import React, { useState, useEffect } from "react";

import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import FormDropDown from "../../components/forms/DropDownList/FormDropDown";
import FontPicker from "font-picker-react";
import FormField from "../../components/forms/FormField/FormField";
import GeneralUploader from "../../components/forms/GeneralUploader/GeneralUploader";
import axios from "../../api/axios";
import { addCustomFontRoute, removeCustomFontRoute, uploadCustomAssetRoute } from "../../api/routes";
import DEBUG_LOGGER from "../../helpers/DebugLogger";
import { toast } from "react-toastify";
import "./CustomFontsPopup.scss";
import { applyFontPreview, FontPreviewBlock, getCustomFontUrl } from "../../helpers/CustomFonts";

const CustomFontsPopup = (props) => {
    const [newFontName, setNewFontName] = useState('');
    const [newFontNameError, setNewFontNameError] = useState('');
    const [newFontFileData, setNewFontFileData] = useState(null);
    const [newFileError, setNewFileError] = useState('');

    const [busy, setBusy] = useState(false);

    console.log('CustomFontsPopup props: ', props);

    const addCustomFont = () => {
        let gotErrors = false;
        setNewFontNameError('');
        setNewFileError('');

        if (newFontName === '') {
            setNewFontNameError('Please enter a font name.');
            gotErrors = true;
        }
        if (!newFontFileData) {
            setNewFileError('Please select a font file.');
            gotErrors = true;
        } else {
            // Is the font file name already in use?
            const fileExists = props.customFonts.find(font => font.fontFilename === newFontFileData.file.name);
            if (fileExists) {
                setNewFileError('File already exists.');
                gotErrors = true;
            }
        }
        if (newFontName !== '' && newFontName.length > 50) {
            setNewFontNameError('Font name is too long.');
            gotErrors = true;
        } else {
            // is the font name already in use?
            const fontExists = props.customFonts.find(font => font.fontFamily === newFontName);
            if (fontExists) {
                setNewFontNameError('Font name already exists.');
                gotErrors = true;
            }
        }
        if (gotErrors) {
            return;
        }

        setBusy(true);

        // Upload the font file
        const formData = new FormData();
        formData.append('filename', newFontFileData.file.name);
        formData.append('campaignID', props.campaignId);
        formData.append('type', 'fonts');
        formData.append('file', newFontFileData.file);
        axios.post(uploadCustomAssetRoute, formData, { withCredentials: true })
                .then(res => {
                    console.log('Upload result: ', res);
                    const uploadedFilename = res.data.uploadedFilename;
                    // File successfully uploaded, now add the font to the list
                    axios.post(addCustomFontRoute, {
                        campaignID: props.campaignId,
                        fontFamily: newFontName,
                        fontFilename: uploadedFilename
                    }, { withCredentials: true })
                        .then(res => {
                            console.log('Add Font result: ', res);
                            toast.success('Font Added Successfully');
                            
                            const newFontData = {
                                fontFamily: newFontName,
                                fontFilename: uploadedFilename
                            };
                            if (props.fullCampaignData) {
                                if (!props.fullCampaignData.customFonts) {
                                    props.fullCampaignData.customFonts = [];
                                }
                                props.fullCampaignData.customFonts.push(newFontData);
                            }

                            setBusy(false);
                        })
                        .catch(err => {
                            DEBUG_LOGGER.log('Add Font error: ', err);
                            toast.error('Error Adding Font - ' + err);
                            setBusy(false);
                        });
                })
                .catch(err => {
                    DEBUG_LOGGER.log('Upload error: ', err);
                    toast.error('Error Uploading File - ' + err);
                    setBusy(false);
                });
    }

    const [editCustomFontIndex, setEditCustomFontIndex] = useState(null);
    const [editingFontName, setEditingFontName] = useState('');
    const [editingFontNameError, setEditingFontNameError] = useState('');
    const editCustomFont = (index) => {
        setEditCustomFontIndex(index);
        const fontData = props.customFonts[index];
        applyFontPreview(fontData.fontFamily, getCustomFontUrl(fontData.fontFilename, props.campaignId), '.apply-edit-custom-font');
        setEditingFontName(fontData.fontFamily);
    }
    const closeEditCustomFont = () => {
        setEditCustomFontIndex(null);
    }

    const applyFontChanges = () => {
        let gotErrors = false;
        setEditingFontNameError('');
        setNewFileError('');

        if (editingFontName === '') {
            setEditingFontNameError('Please enter a font name.');
            gotErrors = true;
        }
        if (newFontFileData) {
            // Is the font file name already in use?
            const fileExists = props.customFonts.find(font => font.fontFilename === newFontFileData.file.name);
            if (fileExists && newFontFileData.file.name !== props.customFonts[editCustomFontIndex].fontFilename) {
                setNewFileError('File already exists.');
                gotErrors = true;
            }
        }
        if (editingFontName !== '' && editingFontName.length > 50) {
            setEditingFontNameError('Font name is too long.');
            gotErrors = true;
        } else {
            // is the font name already in use?
            const fontExists = props.customFonts.find(font => font.fontFamily === editingFontName);
            if (fontExists && editingFontName !== props.customFonts[editCustomFontIndex].fontFamily) {
                setEditingFontNameError('Font name already exists.');
                gotErrors = true;
            }
        }
        if (gotErrors) {
            return;
        }

        setBusy(true);

        const sendNewFontData = (uploadedFilename) => {
            axios.post(addCustomFontRoute, {
                campaignID: props.campaignId,
                fontFamily: editingFontName,
                fontFilename: uploadedFilename,
                editFontIndex: editCustomFontIndex
            }, { withCredentials: true })
                .then(res => {
                    console.log('Edit Font result: ', res);
                    toast.success('Font Edited Successfully');
                    
                    const newFontData = {
                        fontFamily: editingFontName,
                        fontFilename: uploadedFilename
                    };
                    if (props.fullCampaignData) {
                        if (!props.fullCampaignData.customFonts) {
                            props.fullCampaignData.customFonts = [];
                        }
                        props.fullCampaignData.customFonts[editCustomFontIndex] = newFontData;
                    }

                    setBusy(false);
                    setEditCustomFontIndex(null);
                    setConfirmRemoveFont(false);
                })
                .catch(err => {
                    DEBUG_LOGGER.log('Edit Font error: ', err);
                    toast.error('Error Editing Font - ' + err);
                    setBusy(false);
                });
        }

        if (newFontFileData) {
            // Upload the font file
            const formData = new FormData();
            formData.append('filename', newFontFileData.file.name);
            formData.append('campaignID', props.campaignId);
            formData.append('type', 'fonts');
            formData.append('file', newFontFileData.file);
            axios.post(uploadCustomAssetRoute, formData, { withCredentials: true })
                    .then(res => {
                        console.log('Upload result: ', res);
                        sendNewFontData(res.data.uploadedFilename);
                    })
                    .catch(err => {
                        DEBUG_LOGGER.log('Upload error: ', err);
                        toast.error('Error Uploading File - ' + err);
                        setBusy(false);
                    });
        } else {
            sendNewFontData(props.customFonts[editCustomFontIndex].fontFilename);
        }
    }

    const [confirmRemoveFont, setConfirmRemoveFont] = useState(false);
    const removeFont = () => {
        setConfirmRemoveFont(true);
    }
    const cancelRemoveFont = () => {
        setConfirmRemoveFont(false);
    }
    const removeFontNow = () => {
        axios.post(removeCustomFontRoute, {
            campaignID: props.campaignId, fontIndex: editCustomFontIndex
        }, { withCredentials: true })
            .then(res => {
                console.log('Remove Font result: ', res);
                toast.success('Font Removed Successfully');
                
                if (props.fullCampaignData) {
                    if (!props.fullCampaignData.customFonts) {
                        props.fullCampaignData.customFonts = [];
                    }
                    props.fullCampaignData.customFonts.splice(editCustomFontIndex, 1);
                }

                setBusy(false);
                setEditCustomFontIndex(null);
                setConfirmRemoveFont(false);
            })
            .catch(err => {
                DEBUG_LOGGER.log('Remove Font error: ', err);
                toast.error('Error Removing Font - ' + err);
                setBusy(false);
            });
    }

    return (
        <>
        {editCustomFontIndex === null &&
            <PopUpPanel closePanelFunc={props.closePanelFunc} className="font-popup">
                <div className="form-holder co-popup-content">
                    <div className="fl-column form-holder">
                        <h2>Custom Fonts</h2>

                        <h3>Your Custom Fonts</h3>
                        <div className="light-text">Custom fonts are added on a campaign level and will be available to other disruptors and features once added.</div>
                        {!props.customFonts?.length &&
                            <div>No custom fonts have been added yet.</div>
                        }
                        {props.customFonts?.length > 0 &&
                            <div className="fl-row fl-wrap">
                                {props.customFonts.map((font, index) => {
                                    return (
                                        <div key={index} className="fl-row compact one-custom-font clickable" onClick={() => editCustomFont(index)}>
                                            <div className="font-name">{font.fontFamily}</div>
                                            <div className="font-file light-text">{font.fontFilename}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        }

                        <hr />

                        <h3>Add New Custom Font</h3>

                        <div className="fl-column">
                            <div className="light-text">This will add a custom font to the list of available fonts for customisable elements.</div>
                            <div className="light-text">We recommend using *.woff or *.woff2 files, although other types are accepted.</div>
                            <div></div>
                        </div>

                        <div className="fl-column compact">
                            <FormField
                                id="newFontName"
                                label="Font Name"
                                labelNote="This is the name that will be used to select the font in the editor and cannot be changed once added."
                                type="text"
                                value={newFontName}
                                setFunc={(val) => setNewFontName(val)}
                                placeholder="Enter font name"
                                error={newFontNameError}
                                readonly={busy}
                            />
                            <div className="fl-column no-gap">
                                <GeneralUploader
                                    label="Font File"
                                    showLabel={false}
                                    uploadImmediately={false}
                                    showRemoveOption={false}
                                    id="newFontFile"
                                    fileSelectedCallback={
                                        (fileObj) => {
                                            console.log('File selected: ', fileObj);
                                            setNewFontFileData(fileObj);
                                        }
                                    }
                                    acceptedFileTypes=".ttf, .otf, .woff, .woff2"
                                    enabled={!busy}
                                />
                                <div className="error">{newFileError}</div>
                            </div>
                        </div>

                        <button className={`submit-btn button-${busy ? 'in' : ''}active`} onClick={busy ? null : addCustomFont}>
                            {`${busy ? 'Adding Font. Please Wait...' : 'Add Custom Font'}`}
                        </button>
                    </div>
                </div>
            </PopUpPanel>
        }
        {editCustomFontIndex !== null && !confirmRemoveFont &&
            <PopUpPanel closePanelFunc={closeEditCustomFont} className="font-popup">
                <div className="form-holder co-popup-content">
                    <div className="fl-column form-holder">
                        <h2>Edit Custom Font</h2>
                        <div className="fl-row">
                            <div className="fl-row compact one-custom-font">
                                <div className="font-name">{props.customFonts[editCustomFontIndex].fontFamily}</div>
                                <div className="font-file light-text">{props.customFonts[editCustomFontIndex].fontFilename}</div>
                            </div>
                            <div className="shrink">
                                <div className="warning-button tight button-active" onClick={removeFont}>Remove Font</div>
                            </div>
                            <div className="grow"></div>
                        </div>
                        <FontPreviewBlock className="apply-edit-custom-font" />

                        <hr />

                        <div className="warning-text">Editing Custom Font Names is not recommended as the changes will not be automatically applied to any disruptors and features currently using this font.</div>

                        <div className="fl-column compact">
                            <FormField
                                id="newFontName"
                                label="Change Font Name"
                                labelNote="This is the name that will be used to select the font in the editor and cannot be changed once added."
                                type="text"
                                value={editingFontName}
                                setFunc={(val) => setEditingFontName(val)}
                                placeholder="Enter font name"
                                error={editingFontNameError}
                                readonly={busy}
                            />
                            <div className="fl-column no-gap">
                                <GeneralUploader
                                    label="Change Font File"
                                    showLabel={true}
                                    uploadImmediately={false}
                                    showRemoveOption={false}
                                    id="newFontFile"
                                    fileSelectedCallback={
                                        (fileObj) => {
                                            console.log('File selected: ', fileObj);
                                            setNewFontFileData(fileObj);
                                        }
                                    }
                                    acceptedFileTypes=".ttf, .otf, .woff, .woff2"
                                    enabled={!busy}
                                />
                                <div className="error">{newFileError}</div>
                            </div>
                        </div>

                        <button className={`submit-btn button-${busy ? 'in' : ''}active`} onClick={busy ? null : applyFontChanges}>
                            {`${busy ? 'Updating Font. Please Wait...' : 'Apply Changes'}`}
                        </button>

                    </div>
                </div>
            </PopUpPanel>
        }
        {confirmRemoveFont &&
            <PopUpPanel closePanelFunc={cancelRemoveFont} className="font-popup">
                <div className="form-holder co-popup-content">
                    <div className="fl-column form-holder">
                        <h2>Remove Custom Font</h2>
                        <h3 className="warning-text">Are you sure you want to remove this font?</h3>
                        <div className="fl-row">
                            <div className="fl-row compact one-custom-font">
                                <div className="font-name">{props.customFonts[editCustomFontIndex].fontFamily}</div>
                                <div className="font-file light-text">{props.customFonts[editCustomFontIndex].fontFilename}</div>
                            </div>
                            <div className="grow"></div>
                        </div>
                        <FontPreviewBlock className="apply-edit-custom-font" />

                        <hr />

                        <div className="warning-text">Removing a custom font will remove it from the list of available fonts for customisable elements. This action cannot be undone.</div>
                        <div className="fl-row">
                            <div className="warning-button button-active" onClick={removeFontNow}>Remove Font</div>
                            <div className="standard-button button-active" onClick={cancelRemoveFont}>Cancel</div>
                        </div>

                    </div>
                </div>
            </PopUpPanel>
        }
        </>
    )
}

export default CustomFontsPopup;
