import { useEffect, useRef, useState } from "react";
import "./RandomiserStatus.scss";

const RandomiserPreviousResults = (props) => {
    const [tick, setTick] = useState(0);
    useEffect(() => {
        setTick((tick) => tick + 1);
    }, [props.featureVars.randomiserPreviousResults.results]);

    const getStateData = () => {
        let stateData = null;
        if (props.previewControls && props?.passedInPreviewData) {
            stateData = props.passedInPreviewData;
        }
        if (stateData === null) {
            stateData = props?.featureVars;
        }
        if (stateData) {
            return stateData;
        }
        // Default state data
        return {};
    }

    const stateData = getStateData();
    console.log('RandomiserPreviousResults props', props);
    console.log('RandomiserPreviousResults stateData', stateData);

    /* if (props.previewControls) {
        return (
            <div className="randomiser-status fl-column">
                <div className="var-label light-text">Previous results are not available in preview.</div>
            </div>
        )
    } */

    return (
        <div className="randomiser-status fl-column">

            {stateData?.randomiserPreviousResults?.results && Array.isArray(stateData.randomiserPreviousResults.results) && stateData.randomiserPreviousResults.results.length > 0 &&
                <div className="randomiser-prev-results-list-box fl-column">
                    {
                        stateData.randomiserPreviousResults.results.map((resultArr, index) => {
                            return (
                                <div className="fl-column compact" key={index}>
                                    <div className="var-label light-text">Spin {stateData.randomiserPreviousResults.results.length - index}</div>
                                    {resultArr.map((result, index) => {
                                        if (result) {
                                            return (
                                                <div key={index} className="one-randomiser-option">
                                                    <div className="bold-text">
                                                        {result.originalOptionData.name}
                                                    </div>
                                                    <div>
                                                        {result.originalOptionData.description}
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </div>
                            )
                        })
                    }
                </div>
            }
            {(!stateData?.randomiserPreviousResults?.results || !Array.isArray(stateData?.randomiserPreviousResults?.results) || stateData?.randomiserPreviousResults?.results?.length <= 0) &&
                <div className="var-label light-text">No previous results to show.</div>
            }

        </div>
    )
}

export default RandomiserPreviousResults;
