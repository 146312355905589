import { useEffect, useState } from "react";

const LiveTextResponseError = (props) => {
    const checkCanShowMessageImmediately = () => {
        const stateData = getStateData();
        let canSendMessageImmediately = false;
        if (stateData.activeMessages.length === 0 || (stateData.activeMessages.length === 1 && !stateData.activeMessages[0].isResponse) || props.customisationData?.setup?.settings?.general?.[0]?.stackMessages === true) {
            canSendMessageImmediately = true;
        }
        if (canSendMessageImmediately !== props.featureVars?.responseTextControls?.canShowMessageImmediately) {
            setTimeout(
                () => props.changeValFunc("responseTextControls", "canShowMessageImmediately", canSendMessageImmediately, false, false, true),
                10
            );
        }
    }

    const [tick, setTick] = useState(0);
    useEffect(() => {
        setTick((t) => t + 1);
        
        checkCanShowMessageImmediately();
    }, [props]);

    useEffect(() => {
        checkCanShowMessageImmediately();
    }, []);

    const getStateData = () => {
        let stateData = null;
        if (props?.passedInPreviewData?.liveTextStatus) {
            stateData = props.passedInPreviewData.liveTextStatus;
        }
        if (stateData === null) {
            stateData = props?.featureVars?.liveTextStatus;
        }
        if (stateData) {
            return stateData;
        }
        // Default state data
        return {
            activeMessages: [],
            queuedMessages: [],
        };
    }

    const stateData = getStateData();

    if (stateData.responseError !== '') {
        return (
            <div className="label warning-text">{stateData.responseError}</div>
        );
    }
    return null;
}

export default LiveTextResponseError;
