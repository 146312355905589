import React, { useState, useEffect } from "react";

import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import FormDropDown from "../../components/forms/DropDownList/FormDropDown";
import FontPicker from "font-picker-react";
import TabbedPanel from "../../components/TabbedPanel/TabbedPanel";
import { getFeatureAssetBaseUrl } from "../../helpers/FeatureAssets";
import { applyFontPreview, FontPreviewBlock, getCustomFontUrl } from "../../helpers/CustomFonts";

const CommonFontPopup = (props) => {
    const customFonts = props.customFonts;

    const [selectedFont, setSelectedFont] = useState('Roboto');
    const [selectedCustomFont, setSelectedCustomFont] = useState(customFonts.length ? customFonts[0].fontFamily : null);

    const isCustomFont = (fontFamily) => {
        if (customFonts) {
            const font = customFonts.find(f => f.fontFamily === fontFamily);
            return font ? true : false;
        }
        return false;
    }
    const initialTab = 'google';
    const [currentTab, setCurrentTab] = useState(initialTab);

    const tabData = [
        { label: 'Google Fonts', id: 'google' },
        { label: 'Custom Fonts', id: 'custom', hidden: (!customFonts || customFonts.length === 0) }
    ];

    const customFontsDropdown = customFonts.map ? customFonts.map(f => ({ label: f.fontFamily, value: f.fontFamily, fontFile: f.fontFilename })) : [];
    const [selectedCustomFontIndex, setSelectedCustomFontIndex] = useState(0);

    const applyCustomFontToPreview = (fontData = null) => {
        if (!customFonts || customFonts.length === 0) {
            return;
        }

        if (fontData === null) {
            fontData = customFontsDropdown[selectedCustomFontIndex];
        }
        applyFontPreview(fontData.value, getCustomFontUrl(fontData.fontFile, props.campaignId));
    }

    useEffect(() => {
        applyCustomFontToPreview();
    }, []);

    const selectFontHandler = (font) => {
        setSelectedFont(font);
    }
    const selectCustomFontHandler = (font) => {
        setSelectedCustomFont(font);
    }

    const setToFontNow = (e) => {
        e.preventDefault();
        props.setCommonFontFunc(currentTab === 'google' ? selectedFont : selectedCustomFont);
        props.closePanelFunc();
    }

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc} className="font-popup">
            <div className="form-holder co-popup-content">
                <form id="setCommonFont" className="form">
                    <h2>Select {props.editingTheme ? 'Theme' : 'Common'} Font</h2>

                    <p className="light-text">This will set the font for all customisable elements to the selected font face.
                    You will still be able to set individual elements to different fonts afterwards.</p>
                       
                    <TabbedPanel
                        tabs={tabData}
                        switchTabCallback={
                            (tabId, tabData) => {
                                setCurrentTab(tabData.id);
                                if (tabData.id === 'custom') {
                                    setTimeout(() => {
                                        applyCustomFontToPreview(customFontsDropdown[selectedCustomFontIndex]);
                                    }, 10);
                                }
                            }
                        }
                        initialTab={tabData.findIndex(t => t.id === initialTab)}
                        tabWidth="33%"
                    >
                        <>
                            {currentTab === 'google' &&
                                <div className="fl-column font-selector-content">
                                    <FontPicker
                                        apiKey="AIzaSyCK-FT-TdFUvWTWBQ_hxsoU_HS6GHeytWg"
                                        activeFontFamily={isCustomFont(selectedFont) ? 'Open Sans' : selectedFont}
                                        limit={250}
                                        onChange={(nextFont) =>
                                            selectFontHandler(nextFont.family)
                                        }
                                    />
                                    <FontPreviewBlock className="apply-font" />
                                </div>
                            }
                            {currentTab === 'custom' &&
                                <div className="fl-column font-selector-content">
                                    <FormDropDown
                                        id="customFonts"
                                        label="Custom Fonts"
                                        showLabel={false}
                                        showErrors={false}
                                        currentIndex={selectedCustomFontIndex}
                                        selectFunc={
                                            (e, id, item) => {
                                                selectCustomFontHandler(item.value); 
                                                setSelectedCustomFontIndex(item.index);
                                                applyCustomFontToPreview(item);
                                            }
                                        }
                                        items={customFontsDropdown}
                                    />
                                    <FontPreviewBlock className="apply-custom-font" />
                                </div>
                            }
                        </>
                    </TabbedPanel>

                    <button className={'submit-btn button-active'} onClick={setToFontNow}>
                        Set All Element Fonts
                    </button>
                </form>
            </div>
        </PopUpPanel>
    )
}

export default CommonFontPopup;
