import React, { useState, useEffect } from "react";

import axios from "../../api/axios";

import { toast } from "react-toastify";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import FormDropDown from "../../components/forms/DropDownList/FormDropDown";
import { getDisruptorListRoute } from "../../api/routes";
import FormField from "../../components/forms/FormField/FormField";
import RadioButtonGroup from "../../components/forms/RadioButtonGroup/RadioButtonGroup";

const AddDisruptor = (props) => {
    const [disruptorName, setDisruptorName] = useState('');
    const [disruptorNameError, setDisruptorNameError] = useState('');

    const [chainPosition, setChainPosition] = useState('start');

    const [rawDisruptorList, setRawDisruptorList] = useState([]);
    const [selectedDisruptor, setSelectedDisruptor] = useState(null);
    const updateDisruptorHandler = (e, dropDownId, item) => {
        console.log('Disruptor selected: ', item);
        setSelectedDisruptor(item);
    }
    const [selectedDisruptorError, setSelectedDisruptorError] = useState('');

    const [selectedExistingDisruptor, setSelectedExistingDisruptor] = useState(null);
    const updateExistingDisruptorHandler = (e, dropDownId, item) => {
        console.log('Existing disruptor selected: ', item);
        setSelectedExistingDisruptor(item);
    }
    const [selectedExistingDisruptorError, setSelectedExistingDisruptorError] = useState('');

    const [isSubmitting, setIsSubmitting] = useState(false);

    const restrictTypeTo = props.restrictTypeTo;

    const saveDisruptor = (e) => {
        e.preventDefault();

        if(!isSubmitting)
        {
            let formValid = true;
            if (addNew) {
                if (selectedDisruptor === null || selectedDisruptor.index === 0) {
                    setSelectedDisruptorError(`Please select a ${!props.isIntro && !props.isOutro ? 'disruptor' : ''}${props.isIntro ? 'intro' : ''}${props.isOutro ? 'outro' : ''} to add`);
                    formValid = false;
                } else {
                    setSelectedDisruptorError('');
                }
                if (disruptorName === '') {
                    setDisruptorNameError(`You need to name your ${!props.isIntro && !props.isOutro ? 'disruptor' : ''}${props.isIntro ? 'intro' : ''}${props.isOutro ? 'outro' : ''}`);
                    formValid = false;
                } else {
                    setDisruptorNameError('');
                }
            } else {
                if (selectedExistingDisruptor === null || selectedExistingDisruptor.index === 0) {
                    setSelectedExistingDisruptorError(`Please select a disruptor to add`);
                    formValid = false;
                } else {
                    setSelectedExistingDisruptorError('');
                }
            }

            if (formValid) {
                if (addNew) {
                    const newDisruptorData = {feature: {_id: selectedDisruptor.id, featureName: selectedDisruptor.label, featureType: "disruptor"}, contentLabel: disruptorName, varKey: selectedDisruptor.value};
                    setIsSubmitting(true);
                    props.addSelectedFeatureToListFunc(newDisruptorData, disruptorSaved, chainPosition === 'start');
                } else {
                    props.addExistingDisruptorToListFunc(selectedExistingDisruptor.value, chainPosition === 'start');
                    props.closePanelFunc();
                }
            }
        }
    }
    useEffect(() => {
        addLeaderboard();
    }, [rawDisruptorList])
    const addLeaderboard = () => {

        if(props.buildLeaderboard)
        {
            console.log('ADD FORCED LB', rawDisruptorList)
            for(let i=0; i<rawDisruptorList.length; i++)
            {
                let rawDisruptorData = rawDisruptorList[i];
                console.log(rawDisruptorData)
                if(rawDisruptorData.varKey == 'leaderboard')
                {
                   
                        const newDisruptorData = {feature: {_id: rawDisruptorData._id, featureName:'Leaderboard', featureType: "disruptor"}, contentLabel: props.disruptorContentLabel ?? 'Quiz Leaderboard', varKey: 'leaderboard'};       
                        props.addSelectedFeatureToListFunc(newDisruptorData, disruptorSaved, chainPosition === 'start');
                  
                }
            }
        }



      
    }
    const disruptorSaved = (success) => {
        setIsSubmitting(false);
        if (success) {
            props.closePanelFunc();
        }
    }

    const fetchDisruptorList = () => {
        axios
            .get(getDisruptorListRoute, { withCredentials: true })
            .then((response) => {
                const disruptorListData = response.data.disruptorList;
                console.log('Disruptor data: ', disruptorListData);
                setRawDisruptorList(disruptorListData);
                console.log('BUILD LEADERBOARD PROP', props.buildLeaderboard)
                //if(props.buildLeaderboard) setTimeout(() => {addLeaderboard()}, 1000);
            })
            .catch((error) => {
                //
                //toast.error(error.response.data.msg);
            });
    }

    const hasPrimaryTrigger = (featureData) => {
        // console.log('Check for primary trigger: ', featureData);
        for (let i = 0; i < featureData.commands.length; i++) {
            if (featureData.commands[i].isPrimaryTrigger) {
                return true;
            }
        }
        return false;
    }

    const getDisruptorListDropDownData = () => {
        const featureDropDownData = [];
        featureDropDownData.push({ id: 0, value: '', label: 'None' });
        for (let i = 0; i < rawDisruptorList.length; i++) {
            if ((!props.isIntro && !props.isOutro && !props.chainableOnly) || (rawDisruptorList[i].isChainable && hasPrimaryTrigger(rawDisruptorList[i]))) {

                console.log('Generate disruptor list: ', rawDisruptorList[i].varKey, restrictTypeTo, props.campaignTier, rawDisruptorList[i].customOnly);
                if (!restrictTypeTo || rawDisruptorList[i].varKey === restrictTypeTo || restrictTypeTo === 'includeFeatures') {
                    if (rawDisruptorList[i].varKey === restrictTypeTo) {
                        featureDropDownData[0] = {index: 1, id: rawDisruptorList[i]._id, value: rawDisruptorList[i].varKey, label: rawDisruptorList[i].disruptorName };
                        if (!selectedDisruptor) {
                            setSelectedDisruptor(featureDropDownData[0]);
                        }
                    } else
                    if(props.campaignTier != 'basic' || !rawDisruptorList[i].customOnly)
                    {
                        featureDropDownData.push({ id: rawDisruptorList[i]._id, value: rawDisruptorList[i].varKey, label: rawDisruptorList[i].disruptorName });
                    }
                }
            }
        }
        return featureDropDownData;
    }

    useEffect(() => {
        fetchDisruptorList();
    }, [])

    const [addNew, setAddNew] = useState(props.isRestricted ? false : true);
    const selectRadioOption = (e, id, val) => {
        setAddNew(val === 'new');
    }
    const getExistingDisruptorOptions = () => {
        const disruptorOptions = [
            {
                id: 0,
                value: null,
                label: 'None',
            }
        ];
        for (let i = 0; i < props.campaignFeatures.length; i++) {
            if ((props.campaignFeatures[i].feature.featureType === 'disruptor' || restrictTypeTo === 'includeFeatures') && ((!props.isIntro && !props.isOutro && !props.chainableOnly) || (props.campaignFeatures[i].feature.isChainable && hasPrimaryTrigger(props.campaignFeatures[i].feature)) || (restrictTypeTo === 'includeFeatures' && props.campaignFeatures[i].feature.featureType === 'feature'))) {
                if (!restrictTypeTo || props.campaignFeatures[i].feature.varKey === restrictTypeTo || restrictTypeTo === 'includeFeatures') {
                    const oneOption = {
                        id: i + 1,
                        value: props.campaignFeatures[i]._id,
                        label: props.campaignFeatures[i].contentLabel + ' (' + props.campaignFeatures[i].feature.featureName + ')',
                    }
                    disruptorOptions.push(oneOption);
                }
            }
        }
        return disruptorOptions;
    }

    return (
        <>
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="form-holder co-popup-content">
            {props.buildLeaderboard &&
                <h2>Adding Leaderboard</h2>
            }
            {!props.buildLeaderboard &&
                <form id="addstream" className="form">
                    {props.disruptorContentLabel && <h2>{props.disruptorContentLabel}</h2>}
                    {!props.disruptorContentLabel && 
                        <h2>Add {props.campaignTier != 'basic' ? '' : 'T3 '}Disruptor{`${props.isIntro || props.isOutro ? ' To Chain' : ''}`} </h2>
                    }
                    {props.showSelectExistingOption &&
                    <div className="fl-row grow">
                        <RadioButtonGroup
                            options={
                                [
                                    {label:"Add New", value: 'new'},
                                    {label:"Select Existing", value: 'existing'},
                                ]
                            }
                            selectedOption={props.isRestricted ? 'existing' : addNew ? 'new' : 'existing'}
                            selectFunc={selectRadioOption}
                            disabled={props.isRestricted}
                        />
                        <div className="grow"></div>
                    </div>
                    }
                    {addNew &&
                    <>
                        <FormField
                            type="text"
                            id="disruptor_name"
                            placeholder={`Enter Disruptor Name`}
                            setFunc={setDisruptorName}
                            label={`Disruptor Name`}
                            error={disruptorNameError}
                            value={disruptorName}
                        />
                        <FormDropDown
                                label={`Select Disruptor`}
                                id="select_disruptor"
                                selectFunc={updateDisruptorHandler}
                                currentIndex={(props.restrictTypeTo && props.restrictTypeTo !== 'includeFeatures') ? 0 : (selectedDisruptor ? selectedDisruptor.index : 0)}
                                items={
                                    getDisruptorListDropDownData()
                                }
                                error={selectedDisruptorError}
                                enabled={!props.restrictTypeTo || props.restrictTypeTo === 'includeFeatures'}
                            />
                    </>
                    }
                    {!addNew &&
                        <>
                            <FormDropDown
                                label={`Select Disruptor`}
                                id="select_existing_disruptor"
                                selectFunc={updateExistingDisruptorHandler}
                                currentIndex={selectedExistingDisruptor ? selectedExistingDisruptor.index : 0}
                                items={
                                    getExistingDisruptorOptions()
                                }
                                error={selectedExistingDisruptorError}
                            />
                        </>
                    }

                    {(props.isIntro || props.isOutro) &&
                        <div className="fl-row">
                            <RadioButtonGroup
                                label="Chain Position"
                                options={[{label: 'Start', value: 'start'}, {label: 'End', value: 'end'}]}
                                selectFunc={(e, id, newValue) => { setChainPosition(newValue) }}
                                selectedOption={chainPosition}
                            />
                        </div>
                    }

                    <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : saveDisruptor}>
                        {isSubmitting ? `Adding Disruptor${props.isIntro || props.isOutro ? ' To Chain' : ''}` : `Add Disruptor${props.isIntro || props.isOutro ? ' To Chain' : ''}`}
                    </button>
                </form>
                }
            </div>
        </PopUpPanel>
        </>
    )
}

export default AddDisruptor;
