import { useEffect, useRef, useState } from "react";
import "./RandomiserStatus.scss";
import { VAR_TYPES } from "../../../../config/FeatureDashboardVarMap";

const RandomiserStatus = (props) => {
    const checkCanStartAndEndTimeoutId = useRef(null);

    const getStateData = () => {
        let stateData = null;
        if (props.previewControls && props?.passedInPreviewData?.randomiserStatus) {
            stateData = props.passedInPreviewData.randomiserStatus;
        }
        if (stateData === null) {
            stateData = props?.featureVars?.randomiserStatus;
        }
        if (stateData) {
            return stateData;
        }
        // Default state data
        return {active: false, spinning: false, spins: 0, lastResult: null};
    }

    const getOptInCount = () => {
        let optInCount = 0;
        if (props.previewControls && props?.passedInPreviewData?.randomiserOptIns) {
            optInCount = props?.passedInPreviewData?.randomiserOptIns?.optInCount ?? 0;
        }
        if (optInCount === 0) {
            optInCount = props?.featureVars?.randomiserOptIns?.optInCount ?? 0;
        }
        return optInCount ?? 0;
    }

    const [tick, setTick] = useState(0);
    useEffect(() => {
        setTick((tick) => tick + 1);
    }, [props.featureVars.randomiserOptIns]);

    const requestDashboardData = () => {
        // console.log('--- REQUEST DASHBOARD DATA ---');
        let optionsData = null;
        if (props.previewControls && props?.passedInPreviewData?.randomiserOptions?.currentOptions) {
            optionsData = props.passedInPreviewData.randomiserOptions?.currentOptions;
        }
        if (optionsData === null) {
            optionsData = props?.featureVars?.randomiserOptions?.currentOptions;
        }

        // console.log('RandomiserStatus requestDashboardData', optionsData, props);

        if (props.featureReady && props.chatConnected) {
            if (!optionsData || optionsData.length === 0) {
                // console.log('RandomiserStatus requestDashboardData - GET_OPTIONS_DATA');
                props.sendFeatureCommandFunc(null, "GET_DASHBOARD_DATA", props.featureId, null);
            }
        }
    }
    useEffect(() => {
        // console.log('RandomiserStatus useEffect');
        requestDashboardData();
    }, [props.featureReady, props.chatConnected]);
    useEffect(() => {
        // console.log('RandomiserStatus useEffect - mount');
        requestDashboardData();
        return () => {
            // console.log('RandomiserStatus useEffect - unmount');
            if (checkCanStartAndEndTimeoutId.current) {
                clearTimeout(checkCanStartAndEndTimeoutId.current);
                checkCanStartAndEndTimeoutId.current = null;
            }
        }
    }, []);

    /* useEffect(() => {
        console.log('RandomiserStatus useEffect - randomiserStatus', JSON.parse(JSON.stringify(props.featureVars)));
        if (!Array.isArray(props.featureVars?.randomiserPreviousResults?.results)) {
            const resultsList = [];
            const stateData = getStateData();
            if (stateData?.lastResult) {
                resultsList.push(JSON.parse(JSON.stringify(stateData.lastResult)));
            }
            props.changeValFunc("randomiserPreviousResults", "results", resultsList, true, false, true, VAR_TYPES.arrayType);
        } else {
            const stateData = getStateData();
            const lastResult = stateData?.lastResult;
            if (lastResult) {
                const lastResultCopy = JSON.parse(JSON.stringify(lastResult));
                if (String(props.featureVars?.randomiserPreviousResults?.results?.[0]) !== String(lastResultCopy)) {
                    const resultsList = [...props.featureVars.randomiserPreviousResults.results];
                    resultsList.unshift(lastResultCopy);
                    props.changeValFunc("randomiserPreviousResults", "results", resultsList, true, false, true, VAR_TYPES.arrayType);
                }
            }
        }
    }, [props.featureVars.randomiserStatus.lastResult]); */

    if (!props.featureReady || !props.chatConnected) {
        return (
            <div className="randomiser-status fl-column">
                <div className={`bold-text error`}>
                    Please load the OBS extension and connect to the chat before using the randomiser!
                </div>
            </div>
        );
    }

    const stateData = getStateData();
    // console.log('RandomiserStatus props', props);
    // console.log('RandomiserStatus stateData', stateData);

    const usingChatOptInData = props?.customisationData?.setup?.globalSettings?.general?.[0]?.useOptInList === true && props?.featureVars?.chatOptIn?.useOptInList === true;
    const optInEnabled = props?.featureVars?.chatOptIn?.enableOptIns === true;
    const numberOfOptIns = getOptInCount();

    const checkCanStartAndEnd = () => {
        if (props.featureVars.randomiserStatusControl.canPreview !== !stateData.active) {
            props.changeValFunc("randomiserStatusControl", "canPreview", !stateData.active, false, false, true);
            props.changeValFunc("randomiserOptionsControl", "canPreview", !stateData.active, false, false, true);
            props.changeValFunc("addRandomiserOptionsControl", "canPreview", !stateData.active, false, false, true);
        }
        const canStart = stateData.active && !stateData.spinning;
        if (props.featureVars.randomiserStatusControl.canStart !== canStart) {
            props.changeValFunc("randomiserStatusControl", "canStart", canStart, false, false, true);
        }
        const canStop = stateData.active && stateData.spinning;
        if (props.featureVars.randomiserStatusControl.canStop !== canStop) {
            props.changeValFunc("randomiserStatusControl", "canStop", canStop, false, false, true);
        }
        const canEnd = stateData.active && !stateData.spinning;
        if (props.featureVars.randomiserStatusControl.canEnd !== canEnd) {
            props.changeValFunc("randomiserStatusControl", "canEnd", canEnd, false, false, true);
        }
        checkCanStartAndEndTimeoutId.current = null;
    }
    /* if (checkCanStartAndEndTimeoutId.current === null) {
        checkCanStartAndEndTimeoutId.current = setTimeout(checkCanStartAndEnd, 100);
    } */

    return (
        <div className="randomiser-status fl-column">
            {!stateData.active && 
                <div className={`status-main-header inactive`}>
                    - INACTIVE -
                </div>
            }
            {stateData.active && stateData.previewing &&
                <div className={`status-main-header active`}>
                    PREVIEW
                </div>
            }
            {stateData.active && stateData.spinning &&
                <div className={`status-main-header active`}>
                    SPINNING
                </div>
            }
            {stateData.active && !stateData.spinning && !stateData.previewing &&
                <div className={`status-main-header inactive`}>
                    STOPPED
                </div>
            }
            {stateData.spins === 0 &&
                <div className="var-label light-text">You haven't spun the randomizer yet!</div>
            }
            {stateData.spins > 0 &&
                <div className="var-label">Spins: {stateData.spins}</div>
            }
            {stateData.spins > 0 && stateData.lastResult &&
                <div className="fl-column compact">
                    <div className="var-label">Last Result:</div>
                    {stateData.lastResult.map((result, index) => {
                        if (result) {
                            return (
                                <div key={index} className="one-randomiser-option">
                                    <div className="bold-text">
                                        {result.originalOptionData.name}
                                    </div>
                                    <div>
                                        {result.originalOptionData.description}
                                    </div>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>
            }
            {usingChatOptInData &&
                <>
                    {(numberOfOptIns > 0 || optInEnabled) &&
                        <div className="light-text bold-text"><span className="confirm-text">Using chat opt-in data:</span> {numberOfOptIns} Users Opted In.</div>
                    }
                    {!optInEnabled && numberOfOptIns === 0 &&
                        <>
                            <div className="light-text bold-text"><span className="confirm-text">Using chat opt-in data:</span> No Users Opted In!</div>
                            <div className="warning-text bold-text">Opting in is currently disabled - toggle Enable Opt-Ins to start collecting users for your draw.</div>
                        </>
                    }
                    {numberOfOptIns === 0 && optInEnabled &&
                        <div className="warning-text bold-text">Please wait until you have some opted in users before showing your randomizer.</div>
                    }
                    {numberOfOptIns > 0 && optInEnabled &&
                        <div className="confirm-text bold-text">Once you have enough users for your randomizer draw, make sure to toggle Enable Opt-Ins to off!</div>
                    }
                </>
            }
        </div>
    );
}

export default RandomiserStatus;
