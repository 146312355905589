import { assets } from "../../assets/assets";
import { useContext, useEffect, useState, } from "react";
import axios from "../../api/axios";
import { bugReportUpdateRoute } from "../../api/routes";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/AuthContext";
// import { getAcTypeData, getAcTypeDisplayName } from "../../helpers/AccountTypes";
import { getShortDateString, getShortDateTimeString } from "../../helpers/Dates";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import DropDownList from "../../components/forms/DropDownList/DropDownList";

import "./EditBugReport.scss";

/**
 * 
 * @param {*} props.bugReport - the BugReport model
 * @param {*} props.priorities - list of available priorities
 * @param {*} props.currentPriorityIndex - currentIndex for the priority DropDownList 
 * @returns 
 */
const EditBugReport = (props) => {

    const { bugReport, priorities, currentPriorityIndex, currentPriorityColourClass, statuses, currentStatusIndex,  currentStatusColourClass, developers, closePanelFunc } = props;

    const [authContext, setAuthContext] = useContext(AuthContext);
    const [ priority, setPriority ] = useState(bugReport.priority);
    const [ status, setStatus ] = useState(bugReport.status);
    // const [ assignedTo, setAssignedTo ] = useState(bugReport.assignedTo);
    const [priorityIndex, setPriorityIndex] = useState(currentPriorityIndex);
    const [statusIndex, setStatusIndex] = useState(currentStatusIndex);
    // const [assignedToIndex, setAssignedToIndex] = useState(currentAssignedToIndex);
    const [priorityColourClass, setPriorityColourClass] = useState(currentPriorityColourClass);
    const [statusColourClass, setStatusColourClass] = useState(currentStatusColourClass);
    const [newNote, setNewNote] = useState("");
    const [replyWindowNotes, setReplyWindowNotes] = useState(null);
    const [newNoteReply, setNewNoteReply] = useState("");
    const [bugReportState, setBugReportState] = useState(bugReport);

    const updateColourClasses = (data) => {
        let priority = (data && data.priority) ? data.priority : bugReport.priority;
        let status = (data && data.status) ? data.status : bugReport.status;

        switch(priority) {
          case "High":
            setPriorityColourClass("high-priority-colour");
            break;
          case "Medium":
            setPriorityColourClass("medium-priority-colour");
            break;
          case "Low":
            setPriorityColourClass("low-priority-colour");
            break;
          case "Wishlist":
            setPriorityColourClass("wishlist-priority-colour");
            break;
        }
  
        switch(status) {
          case "New":
            setStatusColourClass("new-status-colour");
            break;
          case "In Progress":
            setStatusColourClass("inprogress-status-colour");
            break;
          case "On Hold":
            setStatusColourClass("onhold-status-colour");
            break;
          case "Ignored":
            setStatusColourClass("ignored-status-colour");
            break;
          case "Complete":
            setStatusColourClass("complete-status-colour");
            break;
        }
      }
  
      useEffect(() => { 
        updateColourClasses();      
      },[bugReport])
  
      useEffect(() => {
        updateColourClasses();
      },[]);

    const changePriority = (e, id, item) => {
        e.preventDefault();
        
        let priority = item.id;

        setPriority(priority);
        setPriorityIndex(item.index);

        updateColourClasses({priority: priority});
    }
    const changeStatus = (e, id, item) => {
        e.preventDefault();

        let status = item.id;
        
        setStatus(status);
        setStatusIndex(item.index);

        updateColourClasses({status: status});
    }
    // const changeAssignedTo = (e, id, item) => {
    //     e.preventDefault();
        
    //     setAssignedTo(item.id);
    //     setAssignedToIndex(item.index);
    // }

    const addNewNoteReply = (e) => {
        e.preventDefault();
        if (replyWindowNotes && newNoteReply.trim()) {
            const updatedNotes = [...bugReportState.notes];
            const newReply = {
                body: newNoteReply.trim(),
                parentId: replyWindowNotes.note.commentId
            };
            updatedNotes.push(newReply);

            // Update both the bug report state and reply window state
            setBugReportState(prev => ({ ...prev, notes: updatedNotes }));
            setReplyWindowNotes(prev => ({ 
                ...prev, 
                replies: [...prev.replies, newReply]
            }));

            setNewNoteReply(""); // Clear the input field
        }
    };

    const updateBugReport = (e) => {
        e.preventDefault();

        const updatedNotes = [...bugReportState.notes];
        if (newNote.trim()) {
            updatedNotes.unshift({ 
                body: newNote.trim(), 
                parentId: null 
            });
        }

        axios
            .post(bugReportUpdateRoute, {
                "bugReportID": bugReportState._id,
                "priority": priority,
                "status": status,
                // "assignedTo": assignedTo,
                "notes": updatedNotes
            })
            .then(response => {
                if(response.data.success) {
                    toast.success("Bug report updated successfully");
                    closePanelFunc();
                } else {
                    toast.error("Failed to update the bug report");
                }
            })
    }

    const openReplyWindow = (notes) => {
        setReplyWindowNotes(notes);
    };

    const closeReplyWindow = () => {
        setReplyWindowNotes(null);
    };

    return (
        <div className={`edit-bugReport-container ${replyWindowNotes ? "with-reply-column" : ""}`}>
            <PopUpPanel closePanelFunc={closePanelFunc}>
                <div className="columns-container">
                    {/* Left column */}
                    <div className="form-holder">
                        <form className="form">
                            <div className="column-cell">
                                <div className="s-row">
                                    <div className="column-cell">
                                        <div className="s-row">
                                            <div className="column-cell">
                                                <h2>{bugReport.title}</h2>
                                            </div>
                                        </div>
                                        <div className="s-row">
                                            <p className="small-text light-text">Bug Reported {getShortDateString(bugReport.dateCreated)}</p>
                                        </div>
                                        <div className="s-row">
                                            <p className="small-text light-text">Last Updated {getShortDateTimeString(bugReport.dateUpdated)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="s-column small-gap">
                                    <div className="label">Type</div>
                                    <p className="indented tight">{bugReport.type}</p>
                                </div>
                                <div className="s-column small-gap">
                                    <div className="label">Reference Code</div>
                                    <p className="indented tight">{bugReport.jiraIssueKey}</p>
                                </div>
                                <div className="s-column small-gap">
                                    <div className="label">Priority</div>
                                    <DropDownList
                                        id="priority"
                                        items={priorities}
                                        selectFunc={changePriority}
                                        currentIndex={priorityIndex}
                                        icon="priority"
                                        className={priorityColourClass}
                                    />
                                </div>
                                {/* <div className="s-column small-gap">
                                    <div className="label">Assigned to</div>
                                    <DropDownList
                                        id="assignedTo"
                                        items={developers}
                                        selectFunc={changeAssignedTo}
                                        currentIndex={assignedToIndex}
                                        icon="oneUser"
                                    />
                                </div> */}
                                <div className="s-column small-gap">
                                    <div className="label">Reported by</div>
                                    <p className="indented tight">{bugReport.reporter ? ((bugReport.reporter.name === "TWITCH_LOCAL") ? `${bugReport.reporter.firstName} ${bugReport.reporter.lastName}` : bugReport.reporter.name) : 'Unknown'}<br /> {`(${bugReport.reporter ? bugReport.reporter._id : ''})`}</p>
                                </div>
                                <div className="s-column small-gap">
                                    <div className="label">Status</div>
                                    <DropDownList
                                        id="status"
                                        items={statuses}
                                        selectFunc={changeStatus}
                                        currentIndex={statusIndex}
                                        icon="status"
                                        className={statusColourClass}
                                    />
                                </div>

                                <div className="s-column small-gap">
                                    <div className="label">Description</div>
                                    <textarea id="description" className="textarea" readOnly value={bugReport.description}></textarea>
                                </div>
                                <div className="s-column small-gap">
                                    <div className="label">Notes</div>
                                    <textarea id="newNote" className="textarea" cols={3} value={newNote} onChange={(e) => setNewNote(e.target.value)}></textarea>
                                </div>
                                <div className="s-column small-gap">
                                    <div className="existing-notes">
                                        { bugReportState.notes.slice().filter(note => !note.parentId)
                                            .map(parentNote => {
                                                let replies = bugReportState.notes.slice().filter(childNote => childNote.parentId === parentNote.commentId);
                                                return (<div key={parentNote.commentId}>
                                                    <p className="note">{parentNote.body}</p>
                                                    { replies.length > 0 &&
                                                        <div className="note-replies" onClick={e => { openReplyWindow({ note:parentNote, replies:replies}) }}>
                                                            {(replies.length === 1) ? "1 reply" : `${replies.length} replies`}    
                                                        </div>
                                                    }
                                                </div>)
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="s-column">
                                    <button className={'submit-btn button-active'} onClick={updateBugReport}>Update</button>
                                </div>
                            </div>
                        </form>
                    </div>

                    {/* Right column for reply window */}
                    {replyWindowNotes && (
                        <div className="reply-column">
                            <div className="reply-window-note">{replyWindowNotes.note.body}</div>
                            {replyWindowNotes.replies.map(reply => (
                                <div className="reply-window-reply" key={reply.commentId}>
                                    <p className="note">{reply.body}</p>
                                </div>
                            ))}
                            <div className="reply-input">
                                <textarea
                                    id="newNoteReply"
                                    className="textarea"
                                    value={newNoteReply}
                                    onChange={e => setNewNoteReply(e.target.value)}
                                ></textarea>
                                <button className="add-reply-button standard-button" onClick={addNewNoteReply}>Add Reply</button>
                            </div>
                            <div className="close-reply-btn" onClick={closeReplyWindow}>
                                <span className="close-reply-text">Close replies</span>
                                <span className="close-icon">×</span>
                            </div>
                        </div>
                    )}
                </div>
            </PopUpPanel>
        </div>
    );
}

export default EditBugReport;