import { useEffect, useState, } from "react";
import { assets } from "../../assets/assets";
import DropDownList from "../../components/forms/DropDownList/DropDownList";
import Toggle from "../../components/forms/Toggle/Toggle";
import ToggleCheck from "../../components/forms/ToggleCheck/ToggleCheck";
import NavLink from "../.."
import { ACCOUNT_TYPES, getAcTypeData } from "../../helpers/AccountTypes";
import { IconJsxer } from "../../helpers/IconHelper";
import { getShortDateString } from "../../helpers/Dates";

const ListedBugReport = (props) => {
    const { bugReport, priorities, statuses, developers, changePriorityFunc, changeStatusFunc, setBugReportToEdit, prepareEditData } = props;

    let currentPriorityIndex = 0;
    let currentStatusIndex = 0;
    //let currentAssignedToIndex = 0;
    if(priorities && priorities.length > 0) {
      currentPriorityIndex = priorities.filter(it=>it.id == bugReport.priority)[0].index;
    }
    if(statuses && statuses.length > 0) {
      currentStatusIndex = statuses.filter(it=>it.id == bugReport.status)[0].index;
    }

    // if(developers && developers.length > 0 && bugReport.assignedTo) {
    //   for(var key in developers) {
    //     let developer = developers[key];
    //     if(developer.id == bugReport.assignedTo._id) {
    //       currentAssignedToIndex = parseInt(key);
    //     }
    //   }
    // }

    const [ priorityIndex, setPriorityIndex ] = useState(currentPriorityIndex);
    const [ statusIndex, setStatusIndex ] = useState(currentStatusIndex);
    //const [ assignedToIndex, setAssignedToIndex ] = useState(currentAssignedToIndex);
    const [ statusColourClass, setStatusColourClass ] = useState("");
    const [ priorityColourClass, setPriorityColourClass ] = useState("");
    const [ status, setStatus] = useState(bugReport.status);
    const [ priority, setPriority ] = useState(bugReport.priority);
    const [ assignedTo, setAssignedTo ] = useState(bugReport.assignedTo);

    let priorityCases = {};
    let stateCases = {};

    const changePriority = (e, id, item) => {
        e.preventDefault();
        if (changePriorityFunc) {
            changePriorityFunc(e, bugReport._id, item.id);
            setPriorityIndex(item.index);
        }
    }

    // const changeAssignedTo = (e, id, item) => {
    //   e.preventDefault();
    //   if(changeAssignedToFunc) {
    //     changeAssignedToFunc(e, bugReport._id, item.id);
    //     setAssignedToIndex(item.index);
    //   }
    // }

    const changeStatus = (e, id, item) => {
      e.preventDefault();
      if(changeStatusFunc) {
        changeStatusFunc(e, bugReport._id, item.id);
        setStatusIndex(item.index);
      }
    }

    const editBugReport = () => {
      prepareEditData({
        isEditing: true,
        priorityIndex: priorityIndex, 
        priorityColourClass: priorityColourClass, 
        statusIndex: statusIndex, 
        statusColourClass: statusColourClass, 
        //assignedToIndex: assignedToIndex,
      })
      setBugReportToEdit(bugReport);
    }

    const updateColourClasses = () => {

      switch(bugReport.priority) {
        case "High":
          setPriorityColourClass("high-priority-colour");
          break;
        case "Medium":
          setPriorityColourClass("medium-priority-colour");
          break;
        case "Low":
          setPriorityColourClass("low-priority-colour");
          break;
        case "Wishlist":
          setPriorityColourClass("wishlist-priority-colour");
          break;
      }

      switch(bugReport.status) {
        case "New":
          setStatusColourClass("new-status-colour");
          break;
        case "In Progress":
          setStatusColourClass("inprogress-status-colour");
          break;
        case "On Hold":
          setStatusColourClass("onhold-status-colour");
          break;
        case "Ready For QA":
          setStatusColourClass("ready-for-qa-status-colour");
          break;
        case "Twitch Review":
          setStatusColourClass("ready-for-twitch-review-status-colour");
          break;
        case "Promote To Production":
          setStatusColourClass("promote-to-production-status-colour")
          break;
        case "Ignored":
          setStatusColourClass("ignored-status-colour");
          break;
        case "Complete":
          setStatusColourClass("complete-status-colour");
          break;
      }
    }

    useEffect(() => { 
      updateColourClasses();  
    },[bugReport])

    useEffect(() => {
      updateColourClasses();
    },[]);

    const gridItemClass = "grid-item" + ((statusColourClass && statusColourClass != "") ? " row-" + statusColourClass : "");

    return (
        <>
            <div className={gridItemClass}>
                <div className="title">{bugReport.title}</div>
            </div>
            <div className={gridItemClass}>
              <div className="type">{bugReport.type}</div>    
            </div>
            <div className={gridItemClass}>
              <div className="bugReportCode">{bugReport.jiraIssueKey}</div>
            </div>
            <div className={gridItemClass + " grid-item-long"}>
              <span className="description"><a>{bugReport.description}</a></span><br />
            </div>
            <div className={gridItemClass}>
              <DropDownList
                id="priority"
                items={priorities}
                selectFunc={changePriority}
                currentIndex={priorityIndex}
                icon="priority"
                className={priorityColourClass}
              />
            </div>
            {/* <div className={gridItemClass}>
              <DropDownList
                id="assignedTo"
                items={developers}
                selectFunc={changeAssignedTo}
                currentIndex={assignedToIndex}
                icon="oneUser"
                deselectedLabel="Unassigned"
              />
            </div> */}
            <div className={gridItemClass}>
              {bugReport.reporter && <div className="reporter">{(bugReport.reporter.name === "TWITCH_LOCAL") ? `${bugReport.reporter.firstName} ${bugReport.reporter.lastName}` : bugReport.reporter.name}<br /> {`(${bugReport.reporter._id})`}</div>}
              {!bugReport.reporter && <div className="reporter">Unknown</div>}
            </div>
            <div className={gridItemClass}>
              {bugReport.dateCreated && <div className="created">{getShortDateString(bugReport.dateCreated)}</div>}
            </div>
            <div className={gridItemClass}>
              <DropDownList
                id="status"
                items={statuses}
                selectFunc={changeStatus}
                currentIndex={statusIndex}
                icon="status"
                className={statusColourClass}
              />
            </div>
            <div className={gridItemClass}>
              <button className="standard-button" onClick={ editBugReport }>Edit</button>
            </div>

            <div className="grid-row-spacer"></div>
            <div className="grid-row-spacer"></div>
            <div className="grid-row-spacer"></div>
            <div className="grid-row-spacer"></div>
            <div className="grid-row-spacer"></div>
            <div className="grid-row-spacer"></div>
            <div className="grid-row-spacer"></div>
            <div className="grid-row-spacer"></div>
            <div className="grid-row-spacer"></div>
        </>
    );
}

export default ListedBugReport;
