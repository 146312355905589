import { useEffect, useRef, useState } from "react";
import FormDropDown from "../../../forms/DropDownList/FormDropDown";
import "./LivePollSelector.scss";

const LivePollSelector = (props) => {
    // Build our drop down options from props.customisationData.setup.pollData.polls[i].title
    const pollListData = [];
    const pollTypes = [];
    const pollsData = props.customisationData?.setup?.pollData?.polls;
    if (!pollsData) {
        pollListData.push({
            id: 0,
            label: "No Polls Available",
            value: 0,
            val: 0
        });
    } else {
        for (let i = 0; i < props.customisationData.setup.pollData.polls.length; i++) {
            pollListData.push({
                id: i,
                label: props.customisationData.setup.pollData.polls[i].title,
                value: i,
                val: i
            });
            pollTypes.push(props.customisationData.setup.pollData.polls[i].type);
        }
    }

    useEffect(() => {
        console.log('--- LivePollSelector, useEffect: ', props.value, pollListData, props.featureVars, props.passedInPreviewData);
        if (pollListData.length > 1) {
            props.changeValFunc('pollControl', 'selectedPollType', pollTypes[props.value], false, false, false);
        }
        
        // Does the selected poll have any votes?
        const featureVars = props.passedInPreviewData ? props.passedInPreviewData : props.featureVars;
        console.log('--- LivePollSelector, used featureVars: ', featureVars, props.customisationData.setup.pollData.polls[props.value]);
        let pollData = null; // featureVars?.pollControl?.pollsState?.[props.value]; // props.customisationData?.setup?.pollData?.polls?.[props.value];
        if (featureVars?.pollControl?.pollsState) {
            for (let i = 0; i < featureVars.pollControl.pollsState.length; i++) {
                if (featureVars.pollControl.pollsState[i].pollTitle === props.customisationData?.setup.pollData.polls[props.value].title) {
                    pollData = featureVars.pollControl.pollsState[i];
                    break;
                }
            }
        }
        let pollHasVotes = false;
        let correctAnswer = -1;
        if (pollData && pollData.answerState && pollData.answerState.length > 0) {
            for (let i = 0; i < pollData.answerState.length; i++) {
                if (pollData.answerState[i].votePercentage > 0) {
                    pollHasVotes = true;
                }
                if (pollData.answerState[i].correct) {
                    correctAnswer = i;
                }
            }
        }

        console.log('--- LivePollSelector, pollData: ', pollData, pollHasVotes, correctAnswer);

        props.changeValFunc('pollControl', 'pollHasVotes', pollHasVotes, false, false, false);
        props.changeValFunc('pollControl', 'correctAnswer', correctAnswer, true, false, true);

    }, [props.value]);

    // onMount - make sure we have these values!
    useEffect(() => {
        if (props.featureVars.pollControl.pollRunning !== true && props.featureVars.pollControl.pollRunning !== false) {
            props.changeValFunc('pollControl', 'pollRunning', false, false, false, false);
        }
        if (props.featureVars.pollControl.pollVisible !== true && props.featureVars.pollControl.pollVisible !== false) {
            props.changeValFunc('pollControl', 'pollVisible', false, false, false, false);
        }
        // Ensure we have a valid poll index
        let correctPollIndex = props.value;
        if (isNaN(correctPollIndex) || correctPollIndex < 0 || correctPollIndex >= pollListData.length) {
            correctPollIndex = 0;
        }
        if (correctPollIndex !== props.value) {
            props.setFunc(correctPollIndex);
        }

        // Does the selected poll have any votes?
        const featureVars = props.passedInPreviewData ? props.passedInPreviewData : props.featureVars;
        // const pollData = featureVars?.pollControl?.pollsState?.[correctPollIndex]; // props.customisationData?.setup?.pollData?.polls?.[props.value];
        // const pollData = props.customisationData?.setup?.pollData?.polls?.[correctPollIndex];
        let pollData = null;
        if (featureVars?.pollControl?.pollsState) {
            for (let i = 0; i < featureVars.pollControl.pollsState.length; i++) {
                if (featureVars.pollControl.pollsState[i].pollTitle === props.customisationData?.setup.pollData.polls[props.value].title) {
                    pollData = featureVars.pollControl.pollsState[i];
                    break;
                }
            }
        }
        let pollHasVotes = false;
        let correctAnswer = -1;
        if (pollData && pollData.answerState && pollData.answerState.length > 0) {
            for (let i = 0; i < pollData.answerState.length; i++) {
                if (pollData.answerState[i].votePercentage > 0) {
                    pollHasVotes = true;
                }
                if (pollData.answerState[i].correct) {
                    correctAnswer = i;
                }
            }
        }

        // These false, false, false flags are to prevent the changeValFunc from reporting a change and also rebuilding state data
        // we're doing this because we only need to report changes and update state once all these values have changed.
        // The final true, false, false flag tells the dashboard to report the changes, but still hold off on rebuilding state data
        // this is because the state data will get rebuilt anyway after the data is reported - if we use default behaviour we get some thrashing
        // of the state and our button flickers between states, this ensures we only update the state once.

        console.log('--- LivePollSelector, pollData: ', pollData, pollHasVotes, correctAnswer);

        props.changeValFunc('pollControl', 'pollHasVotes', pollHasVotes, false, false, false);
        props.changeValFunc('pollControl', 'correctAnswer', correctAnswer, false, false, false);
        props.changeValFunc('pollControl', 'selectedPollType', pollTypes[correctPollIndex], true, false, true);
    }, []);

    const featureVars = props.passedInPreviewData ? props.passedInPreviewData : props.featureVars;

    return (
        <>
            <FormDropDown
                label={props.label}
                items={pollListData}
                value={props.value}
                selectFunc={
                    (e, id, item) => {
                        props.setFunc(item.index)
                        // props.changeValFunc('pollControl', 'selectedPollType', pollTypes[item.index]);
                    }
                }
                currentIndex={props.value}
                showErrors={false}
                enabled={pollListData.length > 1 && !featureVars?.pollControl?.pollVisible}
            />
        </>
    )
};

export default LivePollSelector;
