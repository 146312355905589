import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import "./ArcadeDataPopup.scss";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import { CampaignContext } from "../../contexts/CampaignContext";
import OneStreamOverview from "../Streams/OneStreamOverview";
import { getTimezoneOptions, parseTimezone } from "../../helpers/TimeZones";
import axios from "../../api/axios";
import { getArcadeEntriesRoute } from "../../api/routes";
import CollapsibleHeadedSection from "../CollapsibleHeadedSection/CollapsibleHeadedSection";
import { convertMSToHMS, convertSecondsToHMS, getDefaultTimezone, getShortDateNumericStringForFilenames, getShortDateTimeString, getShortTimeString, getTzLongDateString } from "../../helpers/Dates";
import { IconJsxer } from "../../helpers/IconHelper";
import XLSX from 'xlsx';
import { alphaTypePrimer, numberTypePrimer, sortListOn } from "../../helpers/ListFunctions";
import FormDropDown from "../forms/DropDownList/FormDropDown";

const ArcadeDataPopup = (props) => {
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);
    const streams_ref = useRef([...campaignContext.campaignData.streams]);

    const arcadeDataFetched_ref = useRef({});
    const [arcadeDataFetched, setArcadeDataFetched] = useState({});
    const arcadeData_ref = useRef({});
    const [arcadeData, setArcadeData] = useState({});

    const getArcadeDataForStreamID = (streamID, featureId = null, force = false) => {
        if (!arcadeDataFetched[streamID] || force) {
            axios.get(getArcadeEntriesRoute, {
                params: {
                    streamID: streamID,
                    featureID: featureId
                }
            }).then(response => {
                // Sort the response data in reverse order based on entry.activationTime
                response.data.sort((a, b) => {
                    return b.activationTime - a.activationTime;
                });
                arcadeData_ref.current[streamID] = response.data;
                arcadeDataFetched_ref.current[streamID] = true;
                setArcadeDataFetched((oldVals) => {
                    return ({
                        ...oldVals,
                        [streamID]: true
                    });
                });
                setArcadeData({
                    ...arcadeData_ref.current
                });
                console.log('Got arcade data:', response.data);
            }).catch(err => {
                console.log(err);
                arcadeDataFetched_ref.current[streamID] = true;
                setArcadeDataFetched((oldVals) => {
                    return ({
                        ...oldVals,
                        [streamID]: true
                    });
                });
            });
        }
    }
    /* useEffect(() => {
        // Sort streams in reverse date order based on stream.startTime
        let streams = streams_ref.current;
        streams.sort((a, b) => {
            return b.startTime - a.startTime;
        });
        console.log('Streams (sorted):', streams);
        streams.forEach(stream => {
            getArcadeDataForStreamID(stream._id);
        });
        getArcadeDataForStreamID('preview_test');
    }, []); */

    const [selectedStream, setSelectedStream] = useState('all');
    const [selectedFeature, setSelectedFeature] = useState(props.featureId ?? 'all');
    const [busy, setBusy] = useState(false);

    const checkAllFetched = (streams) => {
        let allFetched = true;
        console.log('Checking all fetched:', streams, arcadeDataFetched_ref.current);
        streams.forEach(stream => {
            if (!arcadeDataFetched_ref.current[stream._id]) {
                allFetched = false;
            }
        });
        return allFetched;
    }

    const fetchData = () => {
        if (!busy) {
            setBusy(true);

            arcadeData_ref.current = {};
            arcadeDataFetched_ref.current = {};
            setArcadeDataFetched({});
            setArcadeData({});

            const loc_selectedStream = selectedStream;
            const loc_selectedFeature = selectedFeature;

            if (loc_selectedStream === 'all') {
                setTimeout(() => {
                    let streams = streams_ref.current;
                    streams.sort((a, b) => {
                        return b.startTime - a.startTime;
                    });
                    console.log('Streams (sorted):', streams);
                    streams.forEach(stream => {
                        getArcadeDataForStreamID(stream._id, loc_selectedFeature === 'all' ? null : loc_selectedFeature, true);
                    });
                    getArcadeDataForStreamID('preview_test', loc_selectedFeature === 'all' ? null : loc_selectedFeature, true);

                    const haveWeFetchedAll = () => {
                        const allFetched = checkAllFetched(streams);
                        if (allFetched) {
                            setBusy(false);
                        } else {
                            setTimeout(() => {
                                haveWeFetchedAll();
                            }, 100);
                        }
                    }
                    setTimeout(() => {
                        haveWeFetchedAll();
                    }, 1000);
                }, 100);
            } else {
                console.log('Getting data for stream:', loc_selectedStream, loc_selectedFeature);
                getArcadeDataForStreamID(loc_selectedStream, loc_selectedFeature === 'all' ? null : loc_selectedFeature, true);

                const streamDataFetchedCheck = () => {
                    console.log('Checking if all fetched:', loc_selectedStream, arcadeDataFetched_ref.current);
                    const allFetched = arcadeDataFetched_ref.current[loc_selectedStream];
                    if (allFetched) {
                        setBusy(false);
                    } else {
                        setTimeout(() => {
                            streamDataFetchedCheck();
                        }, 100);
                    }
                }
                setTimeout(() => {
                    streamDataFetchedCheck();
                }, 1000);
            }
        }
    }

    const [streamDropDownList, setStreamDropDownList] = useState([]);
    const [selectedStreamDropDownIndex, setSelectedStreamDropDownIndex] = useState(0);
    const [featureDropDownList, setFeatureDropDownList] = useState([]);
    const [selectedFeatureDropDownIndex, setSelectedFeatureDropDownIndex] = useState(0);
    useEffect(() => {
        // Build our drop down list data
        const streamsDropDownList = [{label: 'All', value: 'all'}];
        streams_ref.current.forEach(stream => {
            const timezoneData = parseTimezone(stream.timeZone);
            const startTimeHms = convertMSToHMS(stream.startTime, false, timezoneData.offset);
            const endTimeHms = convertMSToHMS(stream.endTime, false, timezoneData.offset);
            const streamLabel = getTzLongDateString(stream.startTime, timezoneData.offset) + ', ' + startTimeHms.h_str + ':' + startTimeHms.m_str + ' - ' + endTimeHms.h_str + ':' + endTimeHms.m_str + ' (' + timezoneData.label.replace('(', '').replace(')', '') + ')';
            streamsDropDownList.push({label: streamLabel, value: stream._id});
        });
        streamsDropDownList.push({label: 'Preview Test Data', value: 'preview_test'});
        setStreamDropDownList(streamsDropDownList);
        const featuresDropDownList = [{label: 'All', value: 'all'}];
        console.log('Campaign Data:', campaignContext.campaignData);
        campaignContext.campaignData.features.forEach(feature => {
            if (feature.feature.isTwitchArcade) {
                // Check this id isn't already included
                let alreadyIncluded = false;
                featuresDropDownList.forEach(f => {
                    if (f.value === feature.feature._id) {
                        alreadyIncluded = true;
                    }
                });
                if (!alreadyIncluded) {
                    featuresDropDownList.push({label: feature.feature.featureName, value: feature.feature._id});
                    if (feature.feature._id === selectedFeature) {
                        setSelectedFeatureDropDownIndex(featuresDropDownList.length - 1);
                    }
                }
            }
        });
        setFeatureDropDownList(featuresDropDownList);

    }, [streams_ref.current]);

    const capitalizeFirstLetter = (string) => {
        if (typeof string !== 'string') {
            return string;
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const createXlsxData = (entry, timezoneData) => {
        const workBook = XLSX.utils.book_new();

        const campaignSheetData = [];
        campaignSheetData.push(['Campaign Name', campaignContext.campaignData.campaignName]);
        campaignSheetData.push(['Client', campaignContext.campaignData.client]);
        campaignSheetData.push(['Region', capitalizeFirstLetter(campaignContext.campaignData.region)]);
        campaignSheetData.push(['Owner', campaignContext.campaignData.campaignOwnerName]);
        campaignSheetData.push(['Campaign Created', getShortDateTimeString(campaignContext.campaignData.dateCreated)]);
        campaignSheetData.push(['Salesforce No.', campaignContext.campaignData.sfNumber]);
        campaignSheetData.push(['Data Exported', getShortDateTimeString(Date.now())]);
        const campaignSheet = XLSX.utils.aoa_to_sheet(campaignSheetData);
        const max_widths_1 = [campaignSheetData.reduce((w, r) => Math.max(w, String(r[0]).length), 10), campaignSheetData.reduce((w, r) => Math.max(w, String(r[1]).length), 10)];
        campaignSheet['!cols'] = [{ wch: max_widths_1[0] }, { wch: max_widths_1[1] }];
        console.log('Campaign Sheet:', campaignSheet);

        workBook.SheetNames.push('Campaign Data');
        workBook.Sheets['Campaign Data'] = campaignSheet;

        const featureLabel = campaignContext.campaignData.features.find(f => f.feature._id === entry.featureID)?.feature.featureName ?? null;
        let labelToStore = '';
        if (entry.campaignFeatureName) {
            labelToStore = entry.campaignFeatureName;
            if (featureLabel) {
                labelToStore += ` (${featureLabel})`;
            }
        }
        if (!entry.campaignFeatureName && featureLabel) {
            labelToStore = featureLabel;
        }

        const activationSheetData = [];
        if (labelToStore && labelToStore !== '') {
            activationSheetData.push(['Feature', labelToStore]);
        }
        activationSheetData.push(['Activation Time', getTzLongDateString(entry.activationTime, timezoneData.offset)]);
        activationSheetData.push(['(local time)', getShortDateTimeString(entry.activationTime, false)]);
        activationSheetData.push(['Deactivation Time', getTzLongDateString(entry.deActivationTime, timezoneData.offset)]);
        activationSheetData.push(['(local time)', getShortDateTimeString(entry.deActivationTime, false)]);
        activationSheetData.push(['Timezone', timezoneData.label]);
        activationSheetData.push(['', '']);
        activationSheetData.push(['Prize Attempts', entry.attempts]);
        activationSheetData.push(['Prizes Won', entry.prizesWon]);
        activationSheetData.push(['', '']);
        activationSheetData.push(['Game Settings', '']);
        Object.keys(entry.gameSettings).forEach(key => {
            activationSheetData.push([entry.gameSettings[key].label, capitalizeFirstLetter(entry.gameSettings[key].value)]);
        });
        const activationSheet = XLSX.utils.aoa_to_sheet(activationSheetData);
        const max_widths_2 = [activationSheetData.reduce((w, r) => Math.max(w, String(r[0]).length), 10), activationSheetData.reduce((w, r) => Math.max(w, String(r[1]).length), 10)];
        activationSheet['!cols'] = [{ wch: max_widths_2[0] }, { wch: max_widths_2[1] }];
        console.log('Activation Sheet:', activationSheet);

        workBook.SheetNames.push('Activation Data');
        workBook.Sheets['Activation Data'] = activationSheet;

        const prizesSheetData = [];
        prizesSheetData.push(['Name', 'Description', 'Target', 'Quantity']);
        entry.prizes.forEach(prize => {
            prizesSheetData.push([prize.name, prize.description, prize.targetDescription, prize.quantity]);
        });
        const prizesSheet = XLSX.utils.aoa_to_sheet(prizesSheetData);
        const max_widths_3 = [prizesSheetData.reduce((w, r) => Math.max(w, String(r[0]).length), 10), prizesSheetData.reduce((w, r) => Math.max(w, String(r[1]).length), 10), prizesSheetData.reduce((w, r) => Math.max(w, String(r[2]).length), 10), prizesSheetData.reduce((w, r) => Math.max(w, String(r[3]).length), 10)];
        prizesSheet['!cols'] = [{ wch: max_widths_3[0] }, { wch: max_widths_3[1] }, { wch: max_widths_3[2] }, { wch: max_widths_3[3] }];
        console.log('Prizes Sheet:', prizesSheet);

        workBook.SheetNames.push('Prizes');
        workBook.Sheets['Prizes'] = prizesSheet;

        const entriesSheetData = [];
        entriesSheetData.push(['Twitch Handle', 'Channel', 'Time', 'Entry Count']);
        entry.entries.forEach(entry => {
            entriesSheetData.push([entry.twitchHandle, entry.channel, getShortDateTimeString(entry.entryTime, false), entry.entryCount]);
        });
        const entriesSheet = XLSX.utils.aoa_to_sheet(entriesSheetData);
        const max_widths_4 = [entriesSheetData.reduce((w, r) => Math.max(w, String(r[0]).length), 10), entriesSheetData.reduce((w, r) => Math.max(w, String(r[1]).length), 10), entriesSheetData.reduce((w, r) => Math.max(w, String(r[2]).length), 10), entriesSheetData.reduce((w, r) => Math.max(w, String(r[3]).length), 10)];
        entriesSheet['!cols'] = [{ wch: max_widths_4[0] }, { wch: max_widths_4[1] }, { wch: max_widths_4[2] }, { wch: max_widths_4[3] }];
        console.log('Entries Sheet:', entriesSheet);

        workBook.SheetNames.push('Opt Ins');
        workBook.Sheets['Opt Ins'] = entriesSheet;

        const winnersSheetData = [];
        winnersSheetData.push(['Twitch Handle', 'Channel', 'Time', 'Attempts']);
        entry.winners.forEach(entry => {
            winnersSheetData.push([entry.twitchHandle, entry.channel, getShortDateTimeString(entry.time, false), entry.attempts]);
        });
        const winnersSheet = XLSX.utils.aoa_to_sheet(winnersSheetData);
        const max_widths_5 = [winnersSheetData.reduce((w, r) => Math.max(w, String(r[0]).length), 10), winnersSheetData.reduce((w, r) => Math.max(w, String(r[1]).length), 10), winnersSheetData.reduce((w, r) => Math.max(w, String(r[2]).length), 10), winnersSheetData.reduce((w, r) => Math.max(w, String(r[3]).length), 10)];
        winnersSheet['!cols'] = [{ wch: max_widths_5[0] }, { wch: max_widths_5[1] }, { wch: max_widths_5[2] }, { wch: max_widths_5[3] }];
        console.log('Winners Sheet:', winnersSheet);

        workBook.SheetNames.push('Winners');
        workBook.Sheets['Winners'] = winnersSheet;

        const streamMarkersSheetData = [];
        streamMarkersSheetData.push(['Twitch Handle', 'Channel', 'Time', 'Description']);
        entry.streamMarkers.forEach(entry => {
            const tData = convertSecondsToHMS(entry.time, true);
            streamMarkersSheetData.push([entry.twitchHandle, entry.channel, `${tData.h} hrs ${tData.m} mins ${tData.s} secs (${entry.time} seconds)`, entry.description]);
        });
        const streamMarkersSheet = XLSX.utils.aoa_to_sheet(streamMarkersSheetData);
        const max_widths_7 = [streamMarkersSheetData.reduce((w, r) => Math.max(w, String(r[0]).length), 10), streamMarkersSheetData.reduce((w, r) => Math.max(w, String(r[1]).length), 10), streamMarkersSheetData.reduce((w, r) => Math.max(w, String(r[2]).length), 10), streamMarkersSheetData.reduce((w, r) => Math.max(w, String(r[3]).length), 10)];
        streamMarkersSheet['!cols'] = [{ wch: max_widths_7[0] }, { wch: max_widths_7[1] }, { wch: max_widths_7[2] }, { wch: max_widths_7[3] }];
        console.log('Stream Markers Sheet:', streamMarkersSheet);

        workBook.SheetNames.push('Stream Markers');
        workBook.Sheets['Stream Markers'] = streamMarkersSheet;

        const losersSheetData = [];
        losersSheetData.push(['Twitch Handle', 'Channel', 'Time']);
        entry.losers.forEach(entry => {
            losersSheetData.push([entry.twitchHandle, entry.channel, getShortDateTimeString(entry.time, false)]);
        });
        const losersSheet = XLSX.utils.aoa_to_sheet(losersSheetData);
        const max_widths_6 = [losersSheetData.reduce((w, r) => Math.max(w, String(r[0]).length), 10), losersSheetData.reduce((w, r) => Math.max(w, String(r[1]).length), 10), losersSheetData.reduce((w, r) => Math.max(w, String(r[2]).length), 10)];
        losersSheet['!cols'] = [{ wch: max_widths_6[0] }, { wch: max_widths_6[1] }, { wch: max_widths_6[2] }];
        console.log('Losers Sheet:', losersSheet);

        workBook.SheetNames.push('Losers');
        workBook.Sheets['Losers'] = losersSheet;

        return workBook;
    }

    const downloadXlsxData = (entry, timezoneData, index) => {
        const workBook = createXlsxData(entry, timezoneData);
        XLSX.writeFile(workBook, 'ArcadeData_' + (campaignContext.campaignData.campaignName.replace(/[ ]/g, '-')) + '_' + getShortDateNumericStringForFilenames(entry.activationTime) + '_' + (index + 1) + '.xlsx');
    }

    const SORT_TYPES = {
        HANDLE: 'twitchHandle',
        CHANNEL: 'channel',
        ENTRY_TIME: 'entryTime',
        ENTRY_COUNT: 'entryCount'
    }

    const getUserEntriesJsxBlock = (entries, timezoneData, extraHeading = "", extraProp = null, sortFunction = null, currentSort = null, sortListReverse = null, displaySettings = null) => {
        return (
            <div className="fl-column no-gap tight">
                <div className="grid sticky">
                    {shouldDisplay(displaySettings, 'results.twitchHandle') &&
                        <div className="header label" onClick={sortFunction ? (event) => {sortFunction(event, SORT_TYPES.HANDLE)} : null}>{getLabel(displaySettings, 'results.twitchHandle',  'Twitch Handle')} {currentSort === SORT_TYPES.HANDLE ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>
                    }
                    {shouldDisplay(displaySettings, 'results.channel') &&
                        <div className="header label" onClick={sortFunction ? (event) => {sortFunction(event, SORT_TYPES.CHANNEL)} : null}>{getLabel(displaySettings, 'results.channel', 'Channel')} {currentSort === SORT_TYPES.CHANNEL ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>
                    }
                    {shouldDisplay(displaySettings, 'results.entryTime') &&
                        <div className="header label" onClick={sortFunction ? (event) => {sortFunction(event, SORT_TYPES.ENTRY_TIME)} : null}>{getLabel(displaySettings, 'results.entryTime', 'Time')} {currentSort === SORT_TYPES.ENTRY_TIME ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>
                    }
                    {shouldDisplay(displaySettings, 'results.' + extraProp) &&
                        <div className="header label centered" onClick={sortFunction ? (event) => {sortFunction(event, SORT_TYPES.ENTRY_COUNT)} : null}>{extraHeading && extraProp ? extraHeading : ''} {currentSort === SORT_TYPES.ENTRY_COUNT ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>
                    }
                </div>
                    {entries.map((entry, i) => {
                        const startTimeHms = timezoneData ? convertMSToHMS(entry.entryTime || entry.time, false, timezoneData.offset) : null;
                        const altBgClass = i % 2 === 0 ? ' alt-bg' : '';
                        return (
                            <div className={"grid" + altBgClass} key={'entry_' + i}>
                                {entry.includeChannelLinks !== false &&
                                    <>
                                        {shouldDisplay(displaySettings, 'results.twitchHandle') &&
                                            <div className={"data"}><a href={'https://www.twitch.tv/' + entry.twitchHandle} target="_blank">{entry.twitchHandle}</a></div>
                                        }
                                        {shouldDisplay(displaySettings, 'results.channel') &&
                                            <div className={"data"}><a href={'https://www.twitch.tv/' + entry.channel} target="_blank">{entry.channel}</a></div>
                                        }
                                    </>
                                }
                                {entry.includeChannelLinks === false &&
                                    <>
                                        {shouldDisplay(displaySettings, 'results.twitchHandle') &&
                                            <div className={"data"}>{entry.twitchHandle}</div>
                                        }
                                        {shouldDisplay(displaySettings, 'results.channel') &&
                                            <div className={"data"}>{entry.channel}</div>
                                        }
                                    </>
                                }
                                {shouldDisplay(displaySettings, 'results.entryTime') &&
                                    <div className={"data fl-row"}>
                                        {timezoneData &&
                                            <>
                                                <div className='fl-row shrink'>
                                                    {`${startTimeHms.h_str}:${startTimeHms.m_str}`}
                                                </div>
                                            </>
                                        }

                                        <div className='fl-row shrink light-text local-time-list'>
                                            {getShortTimeString(entry.entryTime || entry.time, false)} (Local Time)
                                        </div>
                                    </div>
                                }
                                {shouldDisplay(displaySettings, 'results.' + extraProp) &&
                                    <div className={"data centered"}>{extraHeading && extraProp ? entry[extraProp] : ''}</div>
                                }
                            </div>
                        );
                    })}
                {entries.length === 0 &&
                    <div className="grid">
                        <div className="data" colSpan="5">No Data Recorded</div>
                    </div>
                }
            </div>
        );
    }

    const OptInsList = (props) => {
        const listSliceLength = 25;
        const [sortedOptInList, setSortedOptInList] = useState(props.entries);
        const [optInList, setOptInList] = useState(props.entries.slice(0, listSliceLength));

        const [sortListReverse, setSortListReverse] = useState(false);
        const [currentSort, setCurrentSort] = useState(SORT_TYPES.ENTRY_TIME);

        const sortList = (event, type) => {
            event.preventDefault();
    
            let reverse = !sortListReverse;
            setSortListReverse(reverse);
    
            let sortedList = sortedOptInList.concat();
    
            switch (type){
                case SORT_TYPES.HANDLE:
                    sortListOn(sortedList, 'twitchHandle', reverse, alphaTypePrimer);
                    break;
                case SORT_TYPES.CHANNEL:
                    sortListOn(sortedList, 'channel', reverse, alphaTypePrimer);
                    break;
                case SORT_TYPES.ENTRY_TIME:
                    sortListOn(sortedList, 'entryTime', reverse, numberTypePrimer);
                    break;
                case SORT_TYPES.ENTRY_COUNT:
                    sortListOn(sortedList, 'entryCount', reverse, numberTypePrimer);
                    break;
                default:
                    break;
            }
    
            setCurrentSort(type)
            setSortedOptInList(sortedList);
            setOptInList(sortedList.slice(0, optInList.length));
        }

        const showMore = () => {
            const currentLength = optInList.length;
            const newLength = currentLength + listSliceLength;
            setOptInList(sortedOptInList.slice(0, newLength));
        }

        return (
            <div className="opt-in-scroll-container">
                <div className="fl-column">
                    {getUserEntriesJsxBlock(optInList, props.timezoneData, "Entries", "entryCount", sortList, currentSort, sortListReverse)}
                    {optInList.length < props.entries.length &&
                        <div className="fl-column no-gap">
                            <div className="bold-text light-text under-list">Showing {optInList.length} of {props.entries.length} entries</div>
                            <div className="fl-row">
                                <div className="shrink">
                                    <button className="standard-button tight" onClick={showMore}>Show More</button>
                                </div>
                                <div className="grow"></div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }

    const shouldDisplay = (displaySettings, dataPath) => {
        if (displaySettings?.displaySettings) {
            displaySettings = displaySettings.displaySettings;
        }
        if (!displaySettings || !displaySettings.ignore) {
            return true;
        }
        // displaySettings.ignore is an array of paths to ignore
        return displaySettings.ignore.indexOf(dataPath) === -1;
    }
    const getLabel = (displaySettings, dataPath, label) => {
        if (displaySettings?.displaySettings) {
            displaySettings = displaySettings.displaySettings;
        }
        if (!displaySettings || !displaySettings.relabel) {
            return label;
        }
        // displaySettings.relabel is an array of objects {path: pathString, label: "new label"}
        if (Array.isArray(displaySettings.relabel)) {
            for (let i = 0; i < displaySettings.relabel.length; i++) {
                if (displaySettings.relabel[i].path === dataPath) {
                    return displaySettings.relabel[i].label;
                }
            }
        }
        // Not found - use the original label
        return label;
    }

    const getArcadeDataEntryJsxBlock = (entry, i, timezoneData = null) => {
        const startTimeHms = timezoneData ? convertMSToHMS(entry.activationTime, false, timezoneData.offset) : null;
        const endTimeHms = timezoneData ? convertMSToHMS(entry.deActivationTime, false, timezoneData.offset) : null;
        const featureLabel = campaignContext.campaignData.features.find(f => f.feature._id === entry.featureID)?.feature.featureName ?? null;
        return (
            <div className="arcade-data-box" key={'arcade_data_' + i}>
                <div className='fl-row list-view-content'>
                    <div className="fl-row grow">
                        <div className="light-text bold-text">Activation #{i + 1}</div>
                        {entry.campaignFeatureName &&
                            <div className="light-text bold-text">{entry.campaignFeatureName} {featureLabel && <>({featureLabel})</>}</div>
                        }
                        {!entry.campaignFeatureName &&
                            <div className="light-text bold-text">{featureLabel}</div>
                        }
                        {timezoneData &&
                            <>
                                <div className='fl-row shrink arcade-data-heading-row-block'>
                                    {IconJsxer.GetIcon(IconJsxer.ICONS.scheduleNav, IconJsxer.ICON_STYLES.listIcon)}
                                    {getTzLongDateString(entry.activationTime, timezoneData.offset)}
                                </div>

                                <div className='fl-row shrink arcade-data-heading-row-block'>
                                    {IconJsxer.GetIcon(IconJsxer.ICONS.clock, IconJsxer.ICON_STYLES.listIcon)}
                                    {`${startTimeHms.h_str}:${startTimeHms.m_str} - ${endTimeHms.h_str}:${endTimeHms.m_str} (${timezoneData.label.replace('(', '').replace(')', '')})`}
                                </div>
                            </>
                        }

                        <div className='fl-row shrink arcade-data-heading-row-block light-text sl-local-time'>
                            {IconJsxer.GetIcon(IconJsxer.ICONS.clock, IconJsxer.ICON_STYLES.listIcon)}
                            {getShortDateTimeString(entry.activationTime, false, ', ')} - {getShortTimeString(entry.deActivationTime, false)} (Local Time)
                        </div>

                        <div className="grow"></div>

                        <div className='fl-row shrink arcade-data-heading-row-block bold-text'>
                            {IconJsxer.GetIcon(IconJsxer.ICONS.arcadeAttemps, IconJsxer.ICON_STYLES.listIcon)}
                            {entry.attempts}
                        </div>

                        <div className='fl-row shrink arcade-data-heading-row-block bold-text'>
                            {IconJsxer.GetIcon(IconJsxer.ICONS.arcadePrizes, IconJsxer.ICON_STYLES.listIcon)}
                            {entry.prizesWon}
                        </div>
                        <div className="fl-row shrink edit-icon" onClick={(e) => {e.preventDefault(); e.stopPropagation(); downloadXlsxData(entry, timezoneData, i)}}>
                            {IconJsxer.GetIcon(IconJsxer.ICONS.fileDownload, IconJsxer.ICON_STYLES.listIcon)}
                        </div>

                    </div>
                </div>

                <CollapsibleHeadedSection
                    title={`Details (Opt Ins: ${entry.entries.length}, Winners: ${entry.winners.length})`}
                >
                    <div className="arcade-data-box no-box indented">
                        <CollapsibleHeadedSection
                            title="Game Setup"
                        >
                            <div className="fl-column no-gap tight">
                                <div className="grid game-setup">
                                    {Object.keys(entry.gameSettings).map((key, i) => {
                                        if (key === 'displaySettings' || key === 'relabel') {
                                            return null;
                                        }
                                        return (
                                            <Fragment key={'d_' + i}>
                                                <div className="data light-text">{entry.gameSettings[key].label}</div>
                                                <div className="data">{String(entry.gameSettings[key].value)}</div>
                                            </Fragment>
                                        );
                                    })}
                                </div>
                            </div>
                        </CollapsibleHeadedSection>
                        {shouldDisplay(entry.gameSettings, 'prizes') &&
                            <CollapsibleHeadedSection
                                title={"Prizes (" + entry.prizes.length + ")"}
                            >
                                <div className="fl-column no-gap tight">
                                    <div className="grid prizes">
                                        {shouldDisplay(entry.gameSettings, 'prizes.name') &&
                                            <div className="header label">{getLabel(entry.gameSettings, 'prizes.name', 'Name')}</div>
                                        }
                                        {shouldDisplay(entry.gameSettings, 'prizes.description') &&
                                            <div className="header label">{getLabel(entry.gameSettings, 'prizes.description', 'Description')}</div>
                                        }
                                        {shouldDisplay(entry.gameSettings, 'prizes.targetDescription') &&
                                            <div className="header label">{getLabel(entry.gameSettings, 'prizes.targetDescription', 'Target')}</div>
                                        }
                                        {shouldDisplay(entry.gameSettings, 'prizes.quantity') &&
                                            <div className="header label centered">{getLabel(entry.gameSettings, 'prizes.quantity', 'Quantity')}</div>
                                        }
                                    </div>
                                    {entry.prizes.map((prize, i) => {
                                        const altBgClass = i % 2 === 0 ? ' alt-bg' : '';
                                        return (
                                            <div className={"grid prizes" + altBgClass} key={'p_' + i}>
                                                {shouldDisplay(entry.gameSettings, 'prizes.name') &&
                                                    <div className="data">{prize.name}</div>
                                                }
                                                {shouldDisplay(entry.gameSettings, 'prizes.description') &&
                                                    <div className="data">{prize.description}</div>
                                                }
                                                {shouldDisplay(entry.gameSettings, 'prizes.targetDescription') &&
                                                    <div className="data">{prize.targetDescription}</div>
                                                }
                                                {shouldDisplay(entry.gameSettings, 'prizes.quantity') &&
                                                    <div className="data centered">{prize.quantity}</div>
                                                }
                                            </div>
                                        );
                                    })}
                                    {entry.prizes.length === 0 &&
                                        <div className="grid prizes">
                                            <div className="data" colSpan="4">No Prizes</div>
                                        </div>
                                    }
                                </div>
                            </CollapsibleHeadedSection>
                        }
                        <CollapsibleHeadedSection
                            title={"Opt Ins (" + entry.entries.length + ")"}
                        >
                            {/*getUserEntriesJsxBlock(entry.entries, timezoneData, "Entries", "entryCount")*/}
                            <OptInsList entries={entry.entries} timezoneData={timezoneData} />
                        </CollapsibleHeadedSection>
                        <CollapsibleHeadedSection
                            title={"Winners (" + entry.winners.length + ")"}
                        >
                            {getUserEntriesJsxBlock(entry.winners, timezoneData, getLabel(entry.gameSettings, 'attempts', "Attempts"), "attempts", null, null, null, entry.gameSettings)}
                        </CollapsibleHeadedSection>
                        {shouldDisplay(entry.gameSettings, 'streamMarkers') &&
                            <CollapsibleHeadedSection
                                title={"Stream Markers (" + entry.streamMarkers.length + ")"}
                            >
                                <div className="fl-column no-gap tight">
                                    <div className="grid stream-markers">
                                        {shouldDisplay(entry.gameSettings, 'streamMarkers.twitchHandle') &&
                                            <div className="header label">{getLabel(entry.gameSettings, 'streamMarkers.twitchHandle', 'Twitch Handle')}</div>
                                        }
                                        {shouldDisplay(entry.gameSettings, 'streamMarkers.channel') &&
                                            <div className="header label">{getLabel(entry.gameSettings, 'streamMarkers.channel', 'Channel')}</div>
                                        }
                                        {shouldDisplay(entry.gameSettings, 'streamMarkers.time') &&
                                            <div className="header label">{getLabel(entry.gameSettings, 'streamMarkers.time', 'Time')}</div>
                                        }
                                        {shouldDisplay(entry.gameSettings, 'streamMarkers.description') &&
                                            <div className="header label">{getLabel(entry.gameSettings, 'streamMarkers.description', 'Description')}</div>
                                        }
                                    </div>
                                    {entry.streamMarkers.map((marker, i) => {
                                        const altBgClass = i % 2 === 0 ? ' alt-bg' : '';
                                        const tData = convertSecondsToHMS(marker.time, true);
                                        return (
                                            <div className={"grid stream-markers" + altBgClass} key={'m_' + i}>
                                                {shouldDisplay(entry.gameSettings, 'streamMarkers.twitchHandle') &&
                                                    <div className="data"><a href={'https://www.twitch.tv/' + marker.twitchHandle} target="_blank">{marker.twitchHandle}</a></div>
                                                }
                                                {shouldDisplay(entry.gameSettings, 'streamMarkers.channel') &&
                                                    <div className="data"><a href={'https://www.twitch.tv/' + marker.channel} target="_blank">{marker.channel}</a></div>
                                                }
                                                {shouldDisplay(entry.gameSettings, 'streamMarkers.time') &&
                                                    <div className="data">{`${tData.h} hrs ${tData.m} mins ${tData.s} secs (${marker.time} seconds)`}</div>
                                                }
                                                {shouldDisplay(entry.gameSettings, 'streamMarkers.description') &&
                                                    <div className="data">{marker.description}</div>
                                                }
                                            </div>
                                        );
                                    })}
                                    {entry.streamMarkers.length === 0 &&
                                        <div className="grid stream-markers">
                                            <div className="data" colSpan="4">No Stream Markers Set</div>
                                        </div>
                                    }
                                </div>
                            </CollapsibleHeadedSection>
                        }
                        {shouldDisplay(entry.gameSettings, 'losers') &&
                            <CollapsibleHeadedSection
                                title={"Losers (" + entry.losers.length + ")"}
                            >
                                {getUserEntriesJsxBlock(entry.losers, timezoneData, '', null, null, null, null, entry.gameSettings)}
                            </CollapsibleHeadedSection>
                        }
                    </div>
                </CollapsibleHeadedSection>
            </div>
        );
    }

    const localTimezoneData = parseTimezone(getDefaultTimezone());
    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc} className="arcade-data-popup">
            <div className="form-holder co-popup-content">
                <h2>Arcade Data for {campaignContext.campaignData.campaignName}</h2>
                <div className="fl-row standard-b-margin indented">
                    <FormDropDown
                        label="Select Stream"
                        items={streamDropDownList}
                        currentIndex={selectedStreamDropDownIndex}
                        selectFunc={(e, id, item) => {setSelectedStream(item.value); setSelectedStreamDropDownIndex(item.index);}}
                        showErrors={false}
                        enabled={!busy}
                    />
                    <FormDropDown
                        label="Select Feature / Disruptor"
                        items={featureDropDownList}
                        currentIndex={selectedFeatureDropDownIndex}
                        selectFunc={(e, id, item) => {setSelectedFeature(item.value); setSelectedFeatureDropDownIndex(item.index);}}
                        showErrors={false}
                        enabled={!busy}
                    />
                    <div className="shrink align-bottom">
                        <div className={`standard-button tight button-${busy ? 'in' : ''}active`} onClick={busy ? null : fetchData}>{`${busy ? 'Fetching Data...' : 'Fetch Data'}`}</div>
                    </div>
                </div>
                <div className="arcade-data-scrolling-panel fl-column">
                    {streams_ref.current.map((stream, i) => {
                        if (arcadeDataFetched[stream._id]) {
                            const timezoneData = parseTimezone(stream.timeZone);
                            console.log('Output arcade data: ', arcadeData[stream._id]);
                            return (
                                <OneStreamOverview 
                                    stream={stream} 
                                    timezoneData={timezoneData}
                                    useCustomContentChildren={true}
                                    key={'astream_' + i}
                                >
                                    <>
                                        {!arcadeDataFetched[stream._id] &&
                                            <>
                                                <div className="underlined-panel-heading">                                 
                                                    <h4>Fetching Data...</h4>
                                                </div>
                                            </>
                                        }
                                        {arcadeDataFetched[stream._id] && (!arcadeData[stream._id] || arcadeData[stream._id].length === 0) &&
                                            <div className="underlined-panel-heading">
                                                <h4>No Data To Display</h4>
                                            </div>
                                        }
                                        {arcadeDataFetched[stream._id] && arcadeData[stream._id] && arcadeData[stream._id].length > 0 &&
                                            <>
                                                {/* <div className="underlined-panel-heading">
                                                    <h4>Data From Stream</h4>
                                                </div> */}
                                                <CollapsibleHeadedSection
                                                    title={`Data From Stream (${arcadeData[stream._id].length} Activations)`}
                                                >
                                                    <div className="fl-column activations-list">
                                                        {arcadeData[stream._id].map((entry, i) => {
                                                            return getArcadeDataEntryJsxBlock(entry, i, timezoneData);
                                                        })}
                                                    </div>
                                                </CollapsibleHeadedSection>
                                            </>
                                        } 
                                    </>
                                </OneStreamOverview>
                            );
                        } else {
                            return null;
                        }
                    })}
                    {arcadeDataFetched['preview_test'] &&
                        <div className="arcade-data-box no-gap">
                            <div className="underlined-panel-heading">
                                <h4>Test Data From Previews</h4>
                            </div>
                            <div className="fl-column">
                                {arcadeData['preview_test'].map((entry, i) => {
                                    return getArcadeDataEntryJsxBlock(entry, i, localTimezoneData);
                                })}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </PopUpPanel>
    )
}

export default ArcadeDataPopup;
