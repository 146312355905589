import React, { useContext } from "react";
import { CampaignContext } from "../../../contexts/CampaignContext";
import { IconJsxer } from "../../../helpers/IconHelper";

import "./FeaturePanel.scss";
import Toggle from "../../../components/forms/Toggle/Toggle";
import HMSTimeField from "../../../components/forms/HMSTimeField/HMSTimeField";
import { FindParamArrayFromCustomisations } from "../../../helpers/FeatureControlsHelper";

import LeftColorBlockBox, { LEFT_COLOR_BLOCK_STYLES } from "../../../components/LeftColorBlockBox/LeftColorBlockBox";
import LeftColorBlockHeadedPanel from "../../../components/LeftColorBlockBox/LeftColorBlockHeadedPanel";
import { AuthContext } from "../../../contexts/AuthContext";
import { ACCOUNT_TYPES } from "../../../helpers/AccountTypes";
const DUMMY_COMMANDS = [
    {
        hashtag: 'hashtag1',
        command: 'command1'
    },
    {
        hashtag: 'hashtag2',
        command: 'command2'
    },
    {
        hashtag: 'hashtag3',
        command: 'command3'
    },
    {
        hashtag: 'hashtag4',
        command: 'command4'
    },
    {
        hashtag: 'hashtag5',
        command: 'command5'
    },
    {
        hashtag: 'hashtag1',
        command: 'command1'
    },
    {
        hashtag: 'hashtag2',
        command: 'command2'
    },
    {
        hashtag: 'hashtag3',
        command: 'command3'
    },
    {
        hashtag: 'hashtag4',
        command: 'command4'
    },
    {
        hashtag: 'hashtag5',
        command: 'command5'
    },
    {
        hashtag: 'hashtag1',
        command: 'command1'
    },
    {
        hashtag: 'hashtag2',
        command: 'command2'
    },
    {
        hashtag: 'hashtag3',
        command: 'command3'
    },
    {
        hashtag: 'hashtag4',
        command: 'command4'
    },
    {
        hashtag: 'hashtag5',
        command: 'command5'
    },
]

const FeaturePanel = (props) => {
    const { feature } = props;
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);
    const [authContext, setAuthContext] = useContext(AuthContext);
    // console.log('Feature Panel: ', feature);
    let hasChatCommands = false;
    for (let i = 0; i < campaignContext.campaignData.hashtagCommands.length; i++) {
        if (campaignContext.campaignData.hashtagCommands[i].hashtags.length > 0) {
            for (let j = 0; j < feature.feature.commands.length; j++) {
                if (campaignContext.campaignData.hashtagCommands[i].command === feature.feature.commands[j].command) {
                    hasChatCommands = true;
                    break;
                }
            }
        }
        if (hasChatCommands) {
            break;
        }
    }

    let validCommandStrings = [];
    for (let j = 0; j < feature.feature.commands.length; j++) {
        if (feature.feature.commands[j].isHashtagEnabled) {
            validCommandStrings.push(feature.feature.commands[j].command);
        }
    }

    const findFeatureData = (featureId) => {
        let featureData = null;
        for (let i = 0; i < campaignContext.campaignData.features.length; i++) {
            if (campaignContext.campaignData.features[i]._id === featureId) {
                featureData = campaignContext.campaignData.features[i];
                break;
            }
        }
        return featureData;
    }

    const getFeatureTitleElement = (featureData, isMainFeature = false) => {
        if (featureData) {
            return (
                <>
                    <div>
                        {featureData.contentLabel}

                        <span className={`bot-time-dark`}>
                            &nbsp;({isMainFeature ? 'Main Feature' : featureData.feature.featureName})
                        </span>
                    </div>
                </>
            )
        } else {
            return null;
        }
    }

    return (
        <>
            <LeftColorBlockHeadedPanel
                style={'feature'}
                headingContent={
                    <div className='fl-row list-view-content'>

                        <div>
                            {feature.feature.featureName}
                        </div>

                        {/* <div className={`bot-time-dark`}>
                                        ({val_f.feature.featureName})
                                    </div> */}

                        <div className="grow"></div>

                        {authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level && feature.feature.isTwitchArcade &&
                            <div className="edit-icon" onClick={() => props._openArcadeDataPopup(feature.feature._id)}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.arcade, IconJsxer.ICON_STYLES.campaignPanelTop)}
                            </div>
                        }

                        {authContext.userData.userLevel >= ACCOUNT_TYPES.creative.level &&
                            <div className={`edit-icon${props._gotLiveStream ? ' not-allowed' : ''}`} onClick={props._gotLiveStream ? null : (e) => props._editFeatureSettings(feature, '(Main Feature)')}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.preview, IconJsxer.ICON_STYLES.campaignPanelTop)}
                            </div>
                        }
                        {authContext.userData.userLevel >= ACCOUNT_TYPES.creative.level &&
                            <div className={`edit-icon${props._gotLiveStream ? ' not-allowed' : ''}`} onClick={props._gotLiveStream ? null : (e) => props._customiseFeature(feature._id)}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.edit, IconJsxer.ICON_STYLES.campaignPanelTop)}
                            </div>
                        }

                        {props._editMode &&
                            <>
                                <div className="edit-icon" onClick={(e) => props._removeFeature(feature, props._featureChain)}>
                                    {IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                </div>
                            </>
                        }
                    </div>
                }
            >

                <div className="feature-panel">
                    {props._featureChain &&
                        <>
                            <div className="fl-row">
                                <h4>Feature Chain</h4>
                                <div className="info-icon" onMouseEnter={(e) => props._showTooltipFunc("Set up a chain of disruptors to play one after another before and after your feature, allowing you add intros, outros and more.", e)} onMouseLeave={props._hideTooltipFunc}>
                                    {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                                </div>

                                <div className="grow"></div>
                                {authContext.userData.userLevel >= ACCOUNT_TYPES.creative.level &&
                                    <div className={`add-btn${props._gotLiveStream ? ' not-allowed' : ''}`} onClick={props._gotLiveStream ? null : (e) => props._addDisruptor(e, true, true, props._featureChain)}>
                                        {IconJsxer.GetIcon(IconJsxer.ICONS.plus, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}
                                    </div>
                                }
                            </div>

                            <hr />

                            <div className="inner-panel fl-column compact">


                                {props._featureChain.map(
                                    (chainedFeatureId, index, arr) => {
                                        const featureData = findFeatureData(chainedFeatureId);
                                        if (featureData) {
                                            return (
                                                <div className="fl-row grow" key={"chain_" + index}>
                                                    {props._getDisruptorPanel(
                                                        featureData, index, arr, 
                                                        false, 
                                                        false, 
                                                        0, 
                                                        true, 
                                                        props._featureChain
                                                    )}
                                                    {props._featureChain.length > 0 && authContext.userData.userLevel >= ACCOUNT_TYPES.creative.level &&
                                                        <>
                                                            <div className={`edit-order-button${index > 0 ? '' : ' edit-but-off'}${props._gotLiveStream ? ' not-allowed' : ''}`} onClick={props._gotLiveStream ? null : () => props._changeChainOrder(chainedFeatureId, props._featureChain, -1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronUp, IconJsxer.ICON_STYLES.controlMoreInfo)}</div>
                                                            <div className={`edit-order-button${index < arr.length - 1 ? '' : ' edit-but-off'}${props._gotLiveStream ? ' not-allowed' : ''}`} onClick={props._gotLiveStream ? null : () => props._changeChainOrder(chainedFeatureId, props._featureChain, 1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.controlMoreInfo)}</div>
                                                        </>
                                                    }
                                                </div>
                                            )
                                        }
                                        return null;
                                    }
                                )}

                            </div>

                        </>
                    }

                    <div></div>

                    <div className="fl-row">
                        <h4>Commands</h4>
                    </div>
                    {hasChatCommands &&
                        <div className="inner-panel">
                            <div className="scroll-container">
                                <div className="grid">
                                    <div className="header label fl-row">Feature Control</div>
                                    <div className="header label fl-row">Param</div>
                                    <div className="header label fl-row">Chat Command(s)</div>
                                    <div className="header label fl-row">Cooldown (secs)</div>
                                    <div className="header label fl-row">Restricted</div>
                                    <div className="header label fl-row">
                                        <div className="grow"></div>
                                        {authContext.userData.userLevel >= ACCOUNT_TYPES.creative.level &&
                                            <div className={`add-btn${props._gotLiveStream ? ' not-allowed' : ''}`} onClick={props._gotLiveStream ? null : (e) => props._addHashtag(feature)}>
                                                {IconJsxer.GetIcon(IconJsxer.ICONS.plus, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}
                                            </div>
                                        }
                                    </div>
                                    <hr></hr>
                                    {hasChatCommands && campaignContext.campaignData.hashtagCommands.map(
                                        (val_c, i_c, arr_c) => {
                                            if (feature._id === val_c.featureID && validCommandStrings.indexOf(val_c.command) >= 0 && val_c.hashtags.length > 0) {
                                                return (
                                                    <OneListedCommand
                                                        key={'olc_' + i_c}
                                                        _val={val_c}
                                                        _featureData={feature}
                                                        _editMode={props._editMode}
                                                        _removeHashtag={props._removeHashtag}
                                                        _campaignId={props._campaignId}
                                                        _customisationData={props._customisationData}
                                                        _gotLiveStream={props._gotLiveStream}
                                                    />
                                                )
                                            } else {
                                                return null;
                                            }
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                    {!hasChatCommands &&
                        <div className="inner-panel">
                            <div className="fl-row">
                                <div>
                                    No chat commands set, click the Add Button to add some.
                                </div>
                                {// props._editMode &&
                                    <>
                                        <div className="grow"></div>
                                        <div className="add-btn" onClick={(e) => props._addHashtag(feature)}>
                                            {IconJsxer.GetIcon(IconJsxer.ICONS.plus, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    }
                </div>

                <div></div>

            </LeftColorBlockHeadedPanel>

        </>
    )
}

export const OneListedCommand = (props) => {
    // console.log('One listed command: ', props._val, props._featureData);
    let paramVal = props._val.param;
    if (typeof props._val.param === 'number' && props._val.param >= 0 && props._val.commandParam !== undefined && props._val.commandParam !== props._val.param && typeof props._val.commandParam === 'string') {
        // This commandParam could be a pointer to a customAudio / customVideo or other array within the feature...
        const paramsArray = props._customisationData.current ? FindParamArrayFromCustomisations(props._customisationData.current, props._featureData._id, props._val.commandParam) : null;
        if (paramsArray) {
            paramVal = paramsArray[props._val.param].label || paramsArray[props._val.param].name || 'Item ' + (props._val.param + 1)/*paramsArray[props._val.param].filename || paramsArray[props._val.param].fileName*/;
        } else
            if (props._featureData.feature[props._val.commandParam] && Array.isArray(props._featureData.feature[props._val.commandParam])) {
                // We found a matching property in the feature!
                if (props._featureData.feature[props._val.commandParam].length > props._val.param) {
                    paramVal = props._featureData.feature[props._val.commandParam][props._val.param];
                    if (paramVal.label) {
                        paramVal = paramVal.label;
                    } else
                    if (paramVal.name) {
                        paramVal = paramVal.name;
                    } else
                        paramVal = 'Item ' + (props._val.param + 1);
                        /* if (paramVal.fileName) {
                            paramVal = paramVal.fileName || paramVal.filename;
                        } */
                }
            }
    }
    return (
        <>
            <div className="cell">
                <div className="tag-list">
                    {props._val.hashtags.map(
                        (h_val, h_i, h_arr) => {
                            return (
                                <div key={h_val.hashtag + '_command_' + h_i}>
                                    {props.userLevel >= ACCOUNT_TYPES.koko.level ? props._val.command : (props._val.title || props._val.command)}
                                </div>
                            )
                        }
                    )}
                </div>
            </div>
            {!props._reducedDetail &&
                <div className="cell">
                    <div className="tag-list">
                        {props._val.hashtags.map(
                            (h_val, h_i, h_arr) => {
                                return (
                                    <div className="ellip" key={h_val.hashtag + '_param_' + h_i}>
                                        {(props._val.param === undefined || props._val.param === null || props._val.param === '') && '[none]'}
                                        {props._val.param !== undefined && paramVal}
                                    </div>
                                )
                            }
                        )}
                    </div>
                </div>
            }
            <div className="cell">
                <div className="tag-list">
                    {props._val.hashtags.map(
                        (h_val, h_i, h_arr) => {
                            return (
                                <div key={h_val.hashtag + '_' + h_i} className={h_val.restricted ? 'restricted' : ''}>{h_val.hashtag}</div>
                            )
                        }
                    )}
                </div>
            </div>
            <div className="cell">
                <div className="tag-list">
                    {props._val.hashtags.map(
                        (h_val, h_i, h_arr) => {
                            return (
                                <div key={h_val.hashtag + '_' + h_i}>{h_val.cooldown}</div>
                            )
                        }
                    )}
                </div>
            </div>
            {!props._reducedDetail &&
                <div className="cell">
                    <div className="tag-list">
                        {props._val.hashtags.map(
                            (h_val, h_i, h_arr) => {
                                return (
                                    <div key={h_val.hashtag + '_restricted_' + h_i} className={h_val.restricted ? 'restricted' : ''}>{h_val.restricted ? 'Yes' : 'No'}</div>
                                )
                            }
                        )}
                    </div>
                </div>
            }
            {!props._reducedDetail &&
                <div className="cell fl-row edit-btns">
                    <div className="grow"></div>
                    <div className="tag-list">
                        {props._val.hashtags.map(
                            (h_val, h_i, h_arr) => {
                                return (
                                    <div className="fl-row" key={h_val + '_icon_' + h_i}>
                                        {/* <div className={`edit-icon`} onClick={(e) => props._editHashtag({ campaignID: props._campaignId, featureID: props._val.featureID, command: props._val.command, hashtag: h_val, param: props._val.param })}>
                                            {IconJsxer.GetIcon(
                                                IconJsxer.ICONS.edit,
                                                IconJsxer.ICON_STYLES.settingsEdit,
                                            )}
                                        </div> */}
                                        {props._editMode &&
                                            <div className={`edit-icon`} onClick={(e) => props._removeHashtag({ campaignID: props._campaignId, featureID: props._val.featureID, command: props._val.command, hashtag: h_val, param: props._val.param })}>
                                                {IconJsxer.GetIcon(
                                                    IconJsxer.ICONS.trash,
                                                    IconJsxer.ICON_STYLES.settingsEdit,
                                                )}
                                            </div>
                                        }
                                    </div>
                                )
                            }
                        )}
                    </div>
                </div>
            }
        </>
    )
}

export default FeaturePanel;
