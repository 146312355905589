import "./LeftColorBlockBox.scss";
import PropTypes from 'prop-types';
import { IconJsxer } from "../../helpers/IconHelper";

export const LEFT_COLOR_BLOCK_STYLES = {
    default: {
        color: 'black',
        icon: 'error'
    },
    feature_default: {
        color: 'feature-default',
        icon: 'star'
    },
    disruptor_default: {
        color: 'disruptor-default',
        icon: 'disruptorDefault'
    },
    bot_default: {
        color: 'bot-default',
        icon: 'bot'
    },

    admin_default: {
        color: 'light-red',
        icon: 'error'
    },

    category_imageOverlay: {
        color: 'image-disruptor',
        icon: 'image'
    },

    category_image: {
        color: 'image-disruptor',
        icon: 'image'
    },

    category_animatedOverlay: {
        color: 'image-disruptor',
        icon: 'image'
    },

    category_animated: {
        color: 'image-disruptor',
        icon: 'image'
    },

    category_disruptorVideo: {
        color: 'video-disruptor',
        icon: 'video'
    },

    category_video: {
        color: 'video-disruptor',
        icon: 'video'
    },

    category_disruptorAudio: {
        color: 'audio-disruptor',
        icon: 'music'
    },

    category_audio: {
        color: 'audio-disruptor',
        icon: 'music'
    },

    category_miniFeature: {
        color: 'mini-feature-disruptor',
        icon: 'film'
    },

    // These colours are also used for streams
    campaign_scheduled: {
        color: 'light-yellow',
        icon: 'clock'
    },
    campaign_channel: {
        color: 'twitch',
        icon: 'clock'
    },
    campaign_live: {
        color: 'light-green',
        icon: 'liveStream'
    },
    campaign_ended: {
        color: 'light-red',
        icon: 'powerOff'
    },
    campaign_notset: {
        color: 'light-grey',
        icon: 'error'
    },
    campaign_archived: {
        color: 'light-grey',
        icon: 'archived'
    },
    campaign_cancelled: {
        color: 'light-grey',
        icon: 'error'
    },

    bot_auto: {
        color: 'auto-bot',
        icon: 'automatic'
    },

    bot_manual: {
        color: 'manual-bot',
        icon: 'manual'
    },

    bot_interval: {
        color: 'interval-bot',
        icon: 'interval'
    },

    bot_response: {
        color: 'response-bot',
        icon: 'response'
    },

    bot_archived: {
        color: 'light-grey',
        icon: 'archived'
    },

    disruptor_imageOverlay: {
        color: 'image-disruptor',
        icon: 'image'
    },

    disruptor_animatedOverlay: {
        color: 'image-disruptor',
        icon: 'image'
    },

    disruptor_twitchHeader: {
        color: 'image-disruptor',
        icon: 'image'
    },

    disruptor_statusScreens: {
        color: 'image-disruptor',
        icon: 'image'
    },

    disruptor_disruptorVideo: {
        color: 'video-disruptor',
        icon: 'video'
    },

    disruptor_disruptorAudio: {
        color: 'audio-disruptor',
        icon: 'music'
    },

    disruptor_subBomb: {
        color: 'mini-feature-disruptor',
        icon: 'film'
    },

    disruptor_Confetti: {
        color: 'mini-feature-disruptor',
        icon: 'film'
    },

    disruptor_countdown321: {
        color: 'mini-feature-disruptor',
        icon: 'film'
    },

    an_messages: {
        color: 'an-messages',
        icon: 'commentDots'
    },

    an_brand_engagement: {
        color: 'brand-engagement',
        icon: 'bullhorn'
    },

    an_total_interactions: {
        color: 'total-interactions',
        icon: 'hashtag'
    },

    an_u_users: {
        color: 'u-users',
        icon: 'usersNav'
    },

    live_mode: {
        color: 'live-mode',
        icon: 'lightingBolt'
    },

    preview_mode: {
        color: 'preview-mode',
        icon: 'preview'
    },

    disabled_mode: {
        color: 'disabled-mode',
        icon: 'error'
    },

    warning: {
        color: 'warning',
        icon: 'error'
    },
    warning_orange: {
        color: 'light-yellow',
        icon: 'error'
    },

    quiz: {
        color: 'quiz',
        icon: 'quizColourBlockIcon'
    },
    leaderboard: {
        color: 'leaderboard',
        icon: 'leaderboardColourBlockIcon'
    },

    category_quiz_list: {
        color: 'quiz-list',
        icon: 'quizColourBlockIcon'
    },
    category_leaderboard_list: {
        color: 'leaderboard-list',
        icon: 'leaderboardColourBlockIcon'
    },
}

const LeftColorBlockBox = (props) => {
    const {style, fullWidth, overrideBgColor, children} = props;
    let styleData = style;

    if (typeof styleData === 'string') {
        if (styleData === 'feature') styleData = 'feature_default';
        if (styleData === 'disruptor') styleData = 'disruptor_default';
        styleData = LEFT_COLOR_BLOCK_STYLES[styleData];
    }
    if (typeof styleData === 'undefined') {
        styleData = LEFT_COLOR_BLOCK_STYLES.default;
    }

    const extraStyle = {};
    if (overrideBgColor) {
        extraStyle.backgroundColor = overrideBgColor;
    }
    if(style.customColor)
    {
        extraStyle.borderColor = style.customColor
    }




    return (
        <div className={`left-color-block-box ${fullWidth ? 'lcb-full-width' : 'grow'}`}>
            <div className={`content-block left ${'lcb-' + styleData.color + '-left'}${props.whiteIcon ? ' white-icon' : ''}`} style={{backgroundColor:styleData.customColor}}>
                {props.useImageIcon &&
                 <> <img className='user-profile-pic' src={props.imageIcon}></img> </>
                }
                {!props.useImageIcon &&
                 <> {IconJsxer.GetIcon(styleData.icon, IconJsxer.ICON_STYLES.analyticsColourBlockIcon)}</>
                }
              
            </div>
            <div className={`content-block right ${'lcb-' + styleData.color + '-right'}`} style={extraStyle}>
                {children}
            </div>
        </div>
    )
}

LeftColorBlockBox.propTypes = {
    style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    fullWidth: PropTypes.bool,
    overrideBgColor: PropTypes.string,
};

LeftColorBlockBox.defaultProps = {
    style: 'default',
    fullWidth: true,
}

export default LeftColorBlockBox;
