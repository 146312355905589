import { useEffect, useRef, useState } from "react";
import "./RandomiserStatus.scss";
import Toggle from "../../../forms/Toggle/Toggle";
import FormDropDown from "../../../forms/DropDownList/FormDropDown";
import { VAR_TYPES } from "../../../../config/FeatureDashboardVarMap";

const REEL_STATES = {
    STOPPED: {
        label: 'Stopped',
        colourCss: 'stopped',
    },
    SPINNING: {
        label: 'Spinning',
        colourCss: 'spinning',
    },
    GET_READY_TO_STOP: {
        label: 'Stopping',
        colourCss: 'stopping',
    },
    SLOWING_DOWN: {
        label: 'Stopping',
        colourCss: 'stopping',
    },
    PREVIEW: {
        label: 'Preview',
        colourCss: 'preview',
    },
}

const RandomiserResultSelector = (props) => {
    if (!props.featureReady || !props.chatConnected) {
        return null;
    }

    const getStateData = () => {
        let stateData = null;
        if (props.previewControls && props?.passedInPreviewData) {
            stateData = props.passedInPreviewData;
        }
        if (stateData === null) {
            stateData = props?.featureVars;
        }
        if (stateData) {
            return stateData;
        }
        // Default state data
        return {};
    }

    const stateData = getStateData();
    // console.log('RandomiserResultSelector props', props);
    // console.log('RandomiserResultSelector stateData', stateData);

    const active = stateData?.randomiserStatus?.active;
    const spinning = stateData?.randomiserStatus?.spinning;
    const enableToggle = active === false || spinning === false;
    let toggleTo = enableToggle ? !stateData?.nextOutcome?.fixOutcome : false;
    if (typeof stateData?.nextOutcome?.fixOutcome !== "boolean") {
        toggleTo = true;
    }

    const randomiserType = props?.customisationData?.setup?.globalSettings?.general?.[0]?.randomiserType;
    const reelCount = randomiserType === 'slot'
                       ? props.customisationData.setup.globalSettings.slotSettings[0].reels : 1;

    const dropDownData = [];
    const selectedIndexes = [];
    for (let i = 0; i < stateData?.randomiserOptions?.currentOptions?.length; i++) {
        if (stateData?.randomiserOptions?.currentOptions[i].length) {
            dropDownData.push([]);
            for (let j = 0; j < stateData?.randomiserOptions?.currentOptions[i].length; j++) {
                if (stateData?.randomiserOptions?.currentOptions?.[i]?.[j]?.included && stateData?.randomiserOptions?.currentOptions?.[i]?.[j]?.spent === false) {
                    dropDownData[i].push({
                        label: stateData?.randomiserOptions?.currentOptions[i][j].originalOptionData.name,
                        value: j,
                        val: j,
                        index: j
                    });
                    if (stateData?.nextOutcome?.outcomeIndex?.[i] === j) {
                        selectedIndexes.push(dropDownData[i].length - 1);
                    }
                }
            }
        }
    }

    const usingChatOptInData = props?.customisationData?.setup?.globalSettings?.general?.[0]?.useOptInList === true && props?.featureVars?.chatOptIn?.useOptInList === true;
    const chatDataEnabledReels = [false, false, false, false, false, false, false, false, false, false];
    for (let i = 0; i < reelCount; i++) {
        if (props?.customisationData?.setup?.globalSettings?.slotSettings?.[0]['useOptInList_reel' + (i + 1)] === true) {
            chatDataEnabledReels[i] = true;
        }
    }
    if (usingChatOptInData && (chatDataEnabledReels.indexOf(true) === -1 || reelCount <= 1)) {
        chatDataEnabledReels[0] = true;
    }

    return (
        <div className="randomiser-status fl-column">
            <div className={`fl-row`}>
                <div className="form-field-content label">Fix Next Outcome</div>
                <div className="grow-100"></div>
                <Toggle
                    id={"randomiser_fix_outcome"}
                    currentState={stateData?.nextOutcome?.fixOutcome ?? false}
                    toggleFunc={(e, id) => { props.changeValFunc("nextOutcome", "fixOutcome", toggleTo, true, false, true, VAR_TYPES.booleanType) }}
                    enabled={enableToggle}
                />
            </div>
            {stateData?.nextOutcome?.fixOutcome === true && dropDownData.map((dropDown, index) => {
                if (index >= reelCount) {
                    return null;
                }
                if (!usingChatOptInData || (usingChatOptInData && !chatDataEnabledReels[index])) {
                    return (
                        <FormDropDown
                            key={index}
                            id={"randomiser_outcome_" + index}
                            items={dropDown}
                            currentIndex={ (selectedIndexes[index] > 0 && selectedIndexes[index] < dropDown.length) ? selectedIndexes[index] : 0 }
                            label={`Next Outcome${reelCount > 1 ? " Reel " + (index + 1) : ""}`}
                            selectFunc={
                                (e, id, item) => { 
                                    stateData.nextOutcome.outcomeIndex[index] = item.value; 
                                    props.changeValFunc("nextOutcome", "outcomeIndex", stateData?.nextOutcome?.outcomeIndex); 
                                }
                            }
                            enabled={enableToggle && stateData?.nextOutcome?.fixOutcome === true}
                            showErrors={false}
                        />
                    );
                } else {
                    return (
                        <div key={index} className="light-text bold-text">{`${reelCount > 1 ? "Reel " + (index + 1) + " outcome" : "Outcome"} will be selected from your list of opted in users.`}</div>
                    );
                }
            })}
            {stateData?.nextOutcome?.fixOutcome === false &&
                <div className={`fl-column compact`}>
                    {stateData?.randomiserStatus.spinning === false &&
                        <div className="light-text bold-text">
                            Next outcome will be randomly decided on the next spin
                        </div>
                    }
                    {stateData?.randomiserStatus.spinning === true &&
                        <>
                            <div className="var-label">
                                Next Outcome:
                            </div>
                            {stateData?.nextOutcome?.outcomeIndex.map((index, i) => {
                                if (index === null || index === undefined || index > stateData?.randomiserOptions?.currentOptions[i].length) {
                                    return null;
                                }
                                let statusClass = '';
                                if (stateData?.randomiserStatus?.reelStatus?.[i]) {
                                    statusClass = ' ' + REEL_STATES[stateData?.randomiserStatus?.reelStatus[i].toUpperCase()].colourCss;
                                }
                                // Note that -1 is returned by the disruptor when a reel is using chat opt-in data
                                // This should be the only time we get -1 as an index
                                return (
                                    <div key={i} className={`one-randomiser-option${statusClass}`}>
                                        <div className="bold-text">
                                            {index === -1 && <>Using Opt-In List</>}
                                            {index !== -1 && <>{stateData?.randomiserOptions?.currentOptions[i][index].originalOptionData.name}</>}
                                        </div>
                                        <div>
                                            {index === -1 && <>A user will be randomly selected from the list.</>}
                                            {index !== -1 && <>{stateData?.randomiserOptions?.currentOptions[i][index].originalOptionData.description}</>}
                                        </div>
                                        {statusClass !== '' &&
                                            <div className="status bold-text">
                                                {REEL_STATES[stateData?.randomiserStatus?.reelStatus[i].toUpperCase()].label}
                                            </div>
                                        }
                                    </div>
                                );
                            })}
                        </>
                    }
                </div>
            }
            {stateData?.error && stateData?.error?.message && stateData?.error?.message !== "" &&
                <div className="bold-text error">{stateData.error.message}</div>
            }
        </div>
    );
}

export default RandomiserResultSelector;