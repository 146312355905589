import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DebugLogger from "../../../helpers/DebugLogger";
import axios from "../../../api/axios";
import "./GeneralUploader.scss";
import { IconJsxer } from '../../../helpers/IconHelper';
import FormField from '../FormField/FormField';

/**
 * General uploader component.
 * Select a file to upload and see an immediate preview.
 * Optionally upload the file immediately or return the selected file to a callback so it can be uploaded as part of a larger form.
 * 
 * PROPS:
 * 
 * label = A label for your uploader
 * uploadImmediately = true / false. If true then the uploader will attempt to upload your file to a provided endPoint as soon as it is selected.
 * endPoint = This is your endpoint for immediate uploads
 * removeEndPoint = Only really needed for immediate uploads, an endpoint to call to remove files from the server / db
 * id = An id for your file, when set to uploadImmediately the id will be passed to the endPoint otherwise it is passed to the callbacks detailed below
 * extraData = Optional extra data passed to your endpoint. If this is an object then each property is extracted and passed up alongside your id
 * showRemoveOption = true / false. If true, the remove button will be displayed.
 * acceptedFileTypes = A string defining the accepted file types (e.g., "image/*,audio/*,video/*")
 *                      For fonts: acceptedFileTypes="font/ttf,font/otf,font/woff,font/woff2" or "font/*"
 * 
 * -- Only used when uploadImmediately=false --
 * fileSelectedCallback = A callback that receives an object in this format: {id: id, file: file, originalEvent: e}
 * 
 * -- Only used when uploadImmediately=true --
 * uploadedCallback = A callback that receives (res, file, id)
 * 
 * -- Only used when uploadImmediately=true --
 * uploadErrorCallback = A callback you can use to deal with upload errors
 * 
 * -- Only used when uploadImmediately=true, if false you should use a fileSelected callback instead --
 * removedCallback = A callback you can use to detect when a file is removed
 * 
 * @param {*} props 
 * @returns The component
 */
const GeneralUploader = (props) => {
  const { label, uploadImmediately, endPoint, removeEndPoint, id, extraData, fileSelectedCallback, uploadedCallback, uploadErrorCallback, removedCallback, showRemoveOption, acceptedFileTypes, enabled } = props;
  let currentFile = props.currentFile;
  let currentFileName = props.currentFileName;

  // Warn about missing props...
  if (uploadImmediately && endPoint === '') {
    DebugLogger.error('No endPoint provided to General Uploader, you should provide one if uploadImmediately=true.');
  }
  if (!uploadImmediately && fileSelectedCallback === null) {
    DebugLogger.warn('No fileSelectedCallback provided to General Uploader, you should provide one if uploadImmediately=false.');
  }
  if (showRemoveOption && uploadImmediately && removeEndPoint === '') {
    DebugLogger.error('No removeEndPoint provided to General Uploader, you should provide one if uploadImmediately=true and showRemoveOption=true.');
  }
  if (showRemoveOption && uploadImmediately && removedCallback === null) {
    DebugLogger.warn('You should provide a removedCallback when using uploadImmediately=true and showRemoveOption=true.');
  }

  const [busy, setBusy] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(currentFileName || 'No file selected');
  const fileInputRef = useRef(null);

  useEffect(
    () => {
      setFile(null);
      setFileName(props.currentFileName || 'No file selected');
    },
    [props.currentFile, props.currentFileName]
  )

  const chooseFile = () => {if (!busy && enabled) fileInputRef.current.click();};
  
  const handleFile = (e) => {
    e.preventDefault();
    if (!busy && enabled) {
      if (e.target.files.length === 0) {
        return;
      }
      const file = e.target.files[0];
      console.log('File: ', file);
      setFile(file);
      setFileName(file.name);
      if (uploadImmediately) {
        DebugLogger.log('Attempting to upload file: ', file);
        if (endPoint) {
          const formData = new FormData();
          formData.append('file', file);
          if (id) {
            formData.append('id', id);
          } else {
            DebugLogger.warn('No id provided for file upload.')
          }
          if (extraData) {
            if (typeof extraData === 'string' || typeof extraData === 'number' || typeof extraData === 'boolean') {
              formData.append('extraData', extraData);
            } else {
              for (let prop in extraData) {
                formData.append(prop, extraData[prop]);
              }
            }
          }
          setBusy(true);

          axios.post(endPoint, formData, { withCredentials: true })
            .then(res => {
              DebugLogger.log('Upload result: ', res);
              setBusy(false);
              if (uploadedCallback) {
                uploadedCallback(res, file, id);
              }
            })
            .catch(err => {
              DebugLogger.log('Upload error: ', err);
              setBusy(false);
              if (uploadErrorCallback) {
                uploadErrorCallback(err, id);
              }
            });
        } else {
          DebugLogger.error('Could not upload file, no endPoint provided.');
        }
      } else {
        if (fileSelectedCallback) {
          fileSelectedCallback({id: id, file: file, originalEvent: e});
        }
      }
    }
  }

  const handleRemove = (e) => {
    e.preventDefault();
    if (!busy && enabled) {
      if (uploadImmediately) {
        DebugLogger.log('Attempting to remove file...');
        if (removeEndPoint) {
          const formData = new FormData();
          if (id) {
            formData.append('id', id);
          } else {
            DebugLogger.warn('No id provided for file removal.')
          }
          if (extraData) {
            if (typeof extraData === 'string' || typeof extraData === 'number' || typeof extraData === 'boolean') {
              formData.append('extraData', extraData);
            } else {
              for (let prop in extraData) {
                formData.append(prop, extraData[prop]);
              }
            }
          }
          setBusy(true);

          axios.post(removeEndPoint, formData)
            .then(res => {
              DebugLogger.log('Remove file result: ', res);
              setBusy(false);
              fileInputRef.current.value = '';
              setFileName('No file selected');
              currentFile = null;
              if (removedCallback) {
                removedCallback(id);
              }
            })
            .catch(err => {
              DebugLogger.log('Remove file error: ', err);
              setBusy(false);
            });
        } else {
          DebugLogger.error('Could not remove file, no removeEndPoint provided.');
        }
      } else {
        fileInputRef.current.value = '';
        setFileName('No file selected');
        currentFile = null;
        if (fileSelectedCallback) {
          fileSelectedCallback({id: id, file: null, originalEvent: 'removed'});
        }
        if (removedCallback) {
          removedCallback(id);
        }
      }
    }
  }

  return (
    <div className="file-upload-holder form-holder">
      <div className='fl-row'>
        <div className='fl-column compact grow'>
            {props.showLabel !== false &&
                <div className="label">
                    {label}
                </div>
        }
          <div className='fl-row grow'>
            <div className='shrink upload-btn-holder'>
                <div className={`standard-button tight${(busy || !enabled) ? ' button-inactive' : ' button-active'}`} onClick={(busy || !enabled) ? null : chooseFile}>{(busy || !enabled) ? 'Uploading' : 'Select File'}</div>
            </div>
            <div className='grow'>
                <div className='file-val-holder' onClick={(busy || !enabled) ? null : chooseFile}>{fileName}</div>
            </div>
            {showRemoveOption &&
              <div className='remove-btn' onClick={(busy || !enabled) ? null : handleRemove}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
            }
          </div>
        </div>
      </div>
      <input
        type="file"
        name="file"
        onChange={handleFile}
        hidden
        ref={fileInputRef}
        id={id}
        accept={acceptedFileTypes || '*/*'}
      />
    </div>
  );
}

GeneralUploader.propTypes = {
  label: PropTypes.string,
  currentFile: PropTypes.any,
  currentFileName: PropTypes.string,
  endPoint: PropTypes.string,
  removeEndPoint: PropTypes.string,
  id: PropTypes.string,
  extraData: PropTypes.any,
  uploadImmediately: PropTypes.bool,
  fileSelectedCallback: PropTypes.func,
  uploadedCallback: PropTypes.func,
  uploadErrorCallback: PropTypes.func,
  removedCallback: PropTypes.func,
  showRemoveOption: PropTypes.bool,
  acceptedFileTypes: PropTypes.string,
    enabled: PropTypes.bool,
};

GeneralUploader.defaultProps = {
  label: 'Custom File',
  currentFile: '',
  currentFileName: 'No file selected',
  endPoint: '',
  removeEndPoint: '',
  id: '',
  extraData: null,
  uploadImmediately: false,
  fileSelectedCallback: null,
  uploadedCallback: null,
  uploadErrorCallback: null,
  removedCallback: null,
  showRemoveOption: true,
  acceptedFileTypes: '*/*',
    enabled: true,
}

export default GeneralUploader;
