// User

export const testProfileRoute = "/secure/profile";



/********************************************************
 * AUTH ROUTES
 *********************************************************/
export const loginRoute = "/user/login";
export const logoutRoute = "/user/logout";
export const registerRoute = "/user/signup";
export const confirmLoginRoute = "/user/confirm";
export const sendConfirmationRoute = "/user/send-confirmation"
export const refreshTokenRoute = "/user/refreshToken";
export const forgottenPasswordRoute = "/user/forgotten-password";
export const resetPasswordRoute = "/user/reset-password";
export const checkResetTokenRoute = "/user/check-reset-token";
export const changeTwoFactor = "/user/update-2fa";
export const uploadProfilePicRoute = "/user/upload-profile-image";
export const removeProfilePicRoute = "/user/remove-profile-image";
export const isBotAuthorisedRoute = 'user/bot-authorised';

export const profileRoute = "/secure/profile";
export const changeDisplayNameRoute = "/user/change-display-name"
export const twitchLocalCallbackRoute = "/twitchapi/auth";
export const twitchAuthRoute = "/twitchapi/auth";
export const changeBotStatusRoute ="/user/change-bot-status"; 
export const twitchStreamerExistsRoute ="/user/twitch-user-exists";
export const changePasswordRoute = "/user/change-pwd";
export const userListAllUsersRoute = "/user-list/all-users";
export const changeUserAuthorisationRoute = "/user-list/change-user-authorisation";
export const changeUserTypeRoute = "/user-list/change-user-type";
// export const inviteUsersRoute = "/user-list/invite-users";
export const getAccountSettingsRoute = "/config/account-settings";
export const getAllUserInvitesRoute = "/user-invite/get-all-user-invites"
export const createUserInvitesRoute = "/user-invite/create-user-invites";
export const resentUserInvitesRoute = "/user-invite/resend-user-invites";


export const createArcadeEntriesRoute = "/arcade-data/submit-arcade-data";
export const getArcadeEntriesRoute = "/arcade-data/fetch-arcade-data";


export const filesRoute = `${process.env.REACT_APP_API_BASE_URL}/files`;
export const uploadCustomAssetRoute = `${process.env.REACT_APP_API_BASE_URL}/files/upload-custom-asset`;

export const appAccessTokenRoute = '/twitchapi/app-access-token';

/********************************************************
 * CAMPAIGN ROUTES
 *********************************************************/
export const createCampaignRoute = "/campaigns/create-new";
export const updateCampaignRoute = "/campaigns/update";
export const setCampaignArchivedRoute = "/campaigns/setArchived";
export const getAllCampaignsRoute = "/campaigns/campaign-list-all";
export const getUserCampaigns =  "/campaigns/campaign-list-user";
export const getCampaignsByDateRoute = "/campaigns/campaign-list-date-range"
export const getCampaignOverviewRoute = "/campaigns/campaign-overview";
export const getCampaignByIdRoute = "/campaigns/campaign-by-id";

export const addCampaignUsersRoute = "/campaigns/add-users";
export const deleteCampaignUserRoute = "/campaigns/delete-user";
export const addCampaignBotRoute = "/campaigns/add-bot";
export const updateCampaignBotRoute = "/campaigns/update-bot";
export const archiveCampaignBotRoute = "/campaigns/archive-bot";
export const addUsersByEmailRoute = "/campaigns/add-users-by-email";

export const addHashTagRoute = "/campaigns/add-hashtag";
export const deleteHashTagRoute = "/campaigns/delete-hashtag";
export const updateHashTagsRoute = "/campaigns/update-hashtags";

export const updatedFeatureListRoute = 'campaigns/update-feature-list';

export const addStreamRoute ='campaigns/add-stream';
export const archiveStreamRoute ='campaigns/archive-stream';
export const changeStreamStatusRoute = 'campaigns/change-stream-status';
export const updateStreamRoute ='campaigns/update-stream';
export const newGamePINTRoute ='campaigns/generate-pin';
export const getCampaignFeatureVarsRoute = 'campaigns/feature-vars';
export const updateCampaignFeatureVarsRoute = 'campaigns/update-feature-vars';
export const updateCampaignFeatureVarsFromFeatureRoute = 'campaigns/update-feature-vars-from-feature';
export const getCampaignCustomisationVarsRoute = 'campaigns/customisation-vars';
export const updateCampaignCustomisationVarsRoute = 'campaigns/update-customisation-vars';

export const activateStreamRoute = 'stream/activate';
export const deactivateStreamRoute = 'stream/deactivate';
export const sendBotChatMessageRoute = 'stream/send-bot-msg';
export const pauseBotRoute = 'stream/live-campaign/pause-bot';
export const streamConnectionStatusRoute = 'stream/check-conn-status';
export const checkBotStatusRoute = 'stream/check-bot-status'
export const getChatHistoryRoute = 'stream/chat-history'
export const startLiveStreamRoute = 'stream/live-campaign/start-campaign'
export const stopStreamAnalyticsRoute = 'stream/stop-analytics';
export const pauseStreamBotRoute = 'stream/live-campaign/pause-bot'
export const getFeatureVarsRoute = 'stream/feature-vars';
export const updateFeatureVarsRoute = 'stream/update-feature-vars';
export const updateFeatureVarsFromFeatureRoute = 'stream/update-feature-vars-from-feature';
export const getStreamCustomisationVarsRoute = 'stream/customisation-vars';
export const updateStreamCustomisationVarsRoute = 'stream/update-customisation-vars';
export const rateStreamRoute = 'stream/rate-stream';
export const addStreamChannelRoute = 'stream/add-stream-channel';
export const removeStreamChannelRoute = 'stream/remove-stream-channel';
export const getProfilePicRoute = 'stream/get-profile-pic';

export const getLiveStreamAnalyticData = 'analytics/live-analytics-data';
export const getSessionAnaliticsData = 'analytics/session-analytics-data';
export const getCampaignAnalyticDataRoute = 'analytics/campaign-analytics-data';
export const disableStreamSessionRoute = 'analytics/disable-stream-session';
export const getAnalyticCampaignListRoute = 'analytics/campaign-list';





export const seedFeatureDBRoute = '/features/seed-features'
export const getFeatureListRoute = '/features/get-feature-list'
export const getDisruptorListRoute = '/features/get-disruptor-list'


export const getCampaignThemesRoute = 'campaigns/campaign-themes';
export const getCampaignFeatureThemesRoute = 'campaigns/campaign-feature-themes';
export const addCampaignThemeRoute = 'campaigns/add-theme';
export const updateCampaignThemeRoute = 'campaigns/update-theme';
export const updateMultipleCampaignThemesRoute = 'campaigns/update-multiple-themes';
export const deleteCampaignThemeRoute = 'campaigns/delete-theme';


export const addCustomFontRoute = 'campaigns/add-custom-font';
export const removeCustomFontRoute = 'campaigns/remove-custom-font';


/*****************************************************************
 * BUG REPORT ROUTES
 *****************************************************************/
export const reportBugRoute = 'bug-reports/create-bug-report';
export const bugReportListRoute = 'bug-reports/all-bug-reports';
export const bugReportPrioritiesAndStatusesRoute = 'bug-reports/get-priorities-and-statuses';
export const getDeveloperListRoute = 'user/get-developers-list';
export const bugReportUpdatePriorityRoute = 'bug-reports/update-priority';
export const bugReportUpdateAssignedToRoute = 'bug-reports/update-assigned-to';
export const bugReportUpdateStatusRoute = 'bug-reports/update-status';
export const bugReportUpdateRoute = 'bug-reports/update-bug-report';



/*******************************************************************
 *  QUIZ ROUTES
 *******************************************************************/
export const listQuizzesRoute = "quiz/list-quizzes";
export const getQuizListRoute = "quiz/quizzes";
export const getQuizzesByCampaignRoute = "quiz/quizzes-by-campaign";
export const getGenericQuizzesRoute = "quiz/generic-quizzes";
export const getQuizRoute = "quiz/quiz";
export const getExtraQuizDataRoute = "quiz/extra-quiz-data";
export const createQuizRoute = "quiz/create-quiz";
export const quizUpdateStatusRoute = "quiz/update-status";
export const quizUpdateRoute = "quiz/update-quiz";
export const removeQuizRoute = "quiz/remove-quiz";
export const getQuizzesByIdsRoute = "quiz/quizzes-by-ids";

/*******************************************************************
 *  SEEDER EDITING ROUTES
 *******************************************************************/
export const getSeederListRoute = "features/get-seeder-list";
export const getSeederFileRoute = "features/get-seeder-file";
export const getAllFeaturesListRoute = "features/get-all-features-list";
export const getFeatureDataByIdRoute = "features/get-feature-data-by-id";
