import { useEffect, useRef, useState } from "react";
import "./RandomiserStatus.scss";

const RandomiserAddToReelSelector = (props) => {
    const reelCount = props?.customisationData?.setup?.globalSettings?.general?.[0]?.randomiserType === 'slot'
        ? props.customisationData.setup.globalSettings.slotSettings[0].reels : 1;
    let currentReel = props?.featureVars?.addRandomiserOptionsControl?.selectedReel ?? 0;
    if (currentReel >= reelCount) {
        currentReel = reelCount - 1;
        props.changeValFunc("addRandomiserOptionsControl", "selectedReel", currentReel); 
    }
    const selectedReelHeading = props?.customisationData?.setup?.globalSettings?.slotSettings?.[0]['heading_reel' + (currentReel + 1)] ?? 'Reel ' + (currentReel + 1);

    const getStateData = () => {
        let stateData = null;
        if (props.previewControls && props?.passedInPreviewData) {
            stateData = props.passedInPreviewData;
        }
        if (stateData === null) {
            stateData = props?.featureVars;
        }
        if (stateData) {
            return stateData;
        }
        // Default state data
        return {};
    }

    const stateData = getStateData();

    if (reelCount > 1) {
        return (
            <div className="fl-column">
                <div className="fl-column no-gap">
                    <div className="form-field-content label">Choose Reel To Add To</div>
                    <div className="fl-row compact">
                        {stateData?.randomiserOptions?.currentOptions?.map((reel, index) => {
                            if (index >= reelCount) {
                                return null;
                            }
                            return (
                                <button key={index} className={`small-ropt-control-btn${currentReel === index ? ' green' : ''}`} onClick={(e) => props.changeValFunc("addRandomiserOptionsControl", "selectedReel", index)}>
                                    Reel {index + 1}
                                </button>
                            );
                        })}
                    </div>
                </div>
                <div className="var-label light-text">Add To {selectedReelHeading} Options List</div>
            </div>
        )
    } else {
        return null;
    }
}

export default RandomiserAddToReelSelector;
