import { useEffect, useRef, useState } from "react";
import "./RandomiserStatus.scss";

const RandomiserOptInData = (props) => {
    const [tick, setTick] = useState(0);
    useEffect(() => {
        setTick((tick) => tick + 1);
    }, [props.featureVars.randomiserOptIns]);

    if (!props.featureReady || !props.chatConnected) {
        return null;
    }

    const getStateData = () => {
        let stateData = null;
        if (props.previewControls && props?.passedInPreviewData) {
            stateData = props.passedInPreviewData;
        }
        if (stateData === null) {
            stateData = props?.featureVars;
        }
        if (stateData) {
            return stateData;
        }
        // Default state data
        return {};
    }

    const stateData = getStateData();
    // console.log('RandomiserOptInData props', props);
    // console.log('RandomiserOptInData stateData', stateData);

    const optins = stateData?.randomiserOptIns?.optInCount ?? 0;

    return (
        <div className="var-label">Users Opted In: {optins}</div>
    );
}

export default RandomiserOptInData;
