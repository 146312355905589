import React, { useState, useEffect } from "react";
import validator from "validator";

import { toast } from "react-toastify";
import axios from "../../api/axios";

import { reportBugRoute } from "../../api/routes";
import FormField from "../../components/forms/FormField/FormField";
import FormDropDown from "../../components/forms/DropDownList/FormDropDown";
import TextArea from "../../components/forms/TextArea/TextArea";

import "./SendBugReport.scss";

import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";

const SendBugReport = (props) => {

    const bugReportTypes = [
        { text:"Functionality Issue", value:"Functionality Issue", index:0 }
        ,{ text:"Problem Logging In", value:"Problem Logging In", index:1 }
        ,{ text:"Visual Issue", value:"Visual Issue", index:2 }
        ,{ text:"Misspelt/Incorrect Text", value:"Misspelt/Incorrect Text", index:3 }
        ,{ text:"Suggestion/Request", value:"Suggestion/Request", index:4 }
    ];
    const bugReportPriorities = [
        { text:"Wishlist", value:"Wishlist", index: 0 }
        , { text:"Low", value:"Low", index: 1 }
        , { text:"Medium", value:"Medium", index: 2 }
        , { text:"High", value:"High", index: 3 }
    ]

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [reportBugType, setReportBugType] = useState({});
    const [priority, setPriority] = useState({});

    const [titleError, setTitleError] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [reportBugTypeError, setReportBugTypeError] = useState("");
    const [priorityError, setPriorityError] = useState("");

    const [bugReportReferenceCode, setBugReportReferenceCode] = useState("");

    const [formValid, setFormValid] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSent, setIsSent] = useState(false);

    //const navigate = useNavigate();


    useEffect(() => {
        if(reportBugType.value == undefined) {
            setReportBugType(bugReportTypes[0]);
        }
        if(priority.value == undefined) {
            setPriority(bugReportPriorities[0]);
        }
    }, [])

    const handleValidation = (event) => {
        let formIsValid = true;

        if (!validator.isAlphanumeric(title, undefined, { ignore: ".,!\"'\/() " }) || title.length < 3 || title.length > 100) {
            formIsValid = false;
            setTitleError("Title Not Valid, letters, numbers, and spaces only, must be at least 3 character and no more than 100");
        } else {
            setTitleError("");
        }

        // Allow spaces in display name
        if (description.length < 3 || description.length > 3000) {
            formIsValid = false;
            setDescriptionError("Description Not Valid, must be at least 3 character and no more than 3000");
        } else {
            setDescriptionError("");
        }

        if (!validator.isIn(reportBugType.value, bugReportTypes.map(it => it.value))) {
            formIsValid = false;
            setReportBugTypeError("Type Not Valid");
        } else {
            setReportBugTypeError("");
        }

        if(!validator.isIn(priority.value, bugReportPriorities.map(it => it.value))) {
            formIsValid = false;
            setReportBugTypeError("Priority Not Valid");
        } else {
            setReportBugTypeError("");
        }

        setFormValid(formIsValid);
        return formIsValid;
    };

    const reportBugFormSubmitHandler = (e) => {
        e.preventDefault();
        const formValid = handleValidation();

        if (formValid) {
            setIsSubmitting(true);

            axios
                .post(reportBugRoute, {
                    title: title,
                    description: description,
                    type: reportBugType.value,
                    priority: priority.value
                })
                .then(response => {
                  setIsSubmitting(false);
                
                  toast.success("Bug Reported Successfully!");
                  //navigate("/login");
                  setBugReportReferenceCode(response.data.jiraResponse.key);
                  setIsSent(true);
                
                })
                .catch(error => {
                    console.error("error=", error);
                    setIsSubmitting(false);
                    toast.error('Error - ' + (error.message || error.response.message || error.response.data.message));
                });
        }
    };

    return (
        <PopUpPanel closePanelFunc={() => { props.closePanelFunc(isSent) }}>
            { !isSent &&
              <div className="form-holder">
                  <form id="reportbugform" className="form">
                      <h2>Report Bug</h2>
                      <FormField
                          type="text"
                          id="title"
                          placeholder="Enter Title"
                          setFunc={setTitle}
                          label="Title"
                          error={titleError}
                      />
                      <FormDropDown
                        id="type" 
                        showLabel={true} 
                        label="Type" 
                        labelNote="Select the type that best fits this issue, to help us send this report to the right place." 
                        items={bugReportTypes}
                        currentIndex={reportBugType.index}
                        selectFunc={
                            (e,id,item) => {
                                setReportBugType(item);
                            }
                        } 
                        error={reportBugTypeError} 
                        enabled={true}
                      />
                      <FormDropDown
                        id="type" 
                        showLabel={true} 
                        label="Priorty" 
                        labelNote="Select wishlist if this is just a suggestion or request, select low or medium if something looks or feels wrong, and high if it is preventing you from using the site properly." 
                        items={bugReportPriorities}
                        currentIndex={priority.index}
                        selectFunc={
                            (e,id,item) => {
                                setPriority(item);
                            }
                        } 
                        error={reportBugTypeError} 
                        enabled={true}
                      />
                      <TextArea
                          type="text"
                          id="description"
                          placeholder="Enter Description"
                          setFunc={setDescription}
                          label="Description"
                          error={descriptionError}
                          showError={true}
                          rows={6}
                      />
                      <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : reportBugFormSubmitHandler}>
                          {isSubmitting ? "Submitting" : "Submit"}
                      </button>
                  </form>
              </div>
            }
            { isSent && 
              <div className="bug-report-reference-holder">
                <h2 className="bug-report-reference-title">Bug Reported!</h2>
                <p className="bug-report-reference-text-block">Thanks for getting in touch to report this issue. It has been sent to our development team, who will get to work resolving this issue as soon as they can.</p>
                <p className="bug-report-reference-text-block">If you need to get in touch with us about this and need to identify the report, you can use the reference code below. We have also sent the reference code to you in an email.</p>
                <div className="bug-report-reference-code-holder">
                  <label htmlFor="bug-report-reference-code" className="bug-report-reference-code-label">Bug Report Reference Code:</label>
                  <p className="bug-report-reference-code">{bugReportReferenceCode}</p>
                </div>
                <div className="form-holder">
                    <form className="form">
                        <button className={'submit-btn button-active'} onClick={ (e) => { e.preventDefault(); props.closePanelFunc(isSent); } }>Close</button>
                    </form>
                </div>
              </div>
            }
        </PopUpPanel>
    )
}

export default SendBugReport;
