import { toast } from "react-toastify";
import CirclePerc from "../../components/CirclePerc/CirclePerc";
import HeadedPanel from "../../components/HeadedPanel/HeadedPanel";
import StreamTranscriptItem from "../../components/Streams/StreamTranscriptItem";
import HMSTimeField from "../../components/forms/HMSTimeField/HMSTimeField";
import RadioButtonGroup from "../../components/forms/RadioButtonGroup/RadioButtonGroup";
import Toggle from "../../components/forms/Toggle/Toggle";
import { convertMSToHMS } from "../../helpers/Dates";
import { IconJsxer } from "../../helpers/IconHelper";
import {CalculateEngagementTime} from "../../helpers/AnalyticsFunctions";
import LeftColorBlockHeadedPanel from "../../components/LeftColorBlockBox/LeftColorBlockHeadedPanel";

const TabStreamManager = (props) => {
    // console.log('_streamData: ', props._streamData);
    const streamTargetTime = props._streamData.endTime - props._streamData.startTime;
    const targetTimeHms = convertMSToHMS(streamTargetTime, true);
    const liveTimeObj = props._streamLiveTime || {hoursStr: '00', minutesStr: '00', secondsStr: '00', rawHours: 0, rawMinutes: 0, rawSeconds: 0};
    const liveTimeSecs = liveTimeObj.rawHours * 3600 + liveTimeObj.rawMinutes * 60 + liveTimeObj.rawSeconds;
    const liveTimePerc = liveTimeSecs / (streamTargetTime / 1000) * 100;

    const copyStreamLinkToClipboard = (e) =>{
        navigator.clipboard.writeText(props._streamLink);
        toast.success("Feature URL copied to clipboard!");
      }

    return (
        <div className="split-content">
            
            <div className="column">
        
            

                <div className="underlined-panel-heading">
                    <h4>Stream Overview</h4>
                    <div className="grow"></div>
                </div>
                {!props._chatConnected &&
                    <div className="stream-not-started">
                        <div className="fl-column">
                            <div className="pause-button">{IconJsxer.GetIcon(IconJsxer.ICONS.pause, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                            <div>CHANNEL NOT CONNECTED</div>
                        </div>
                    </div>
                }
                {props._chatConnected && !props._streamStarted &&
                    <div className="stream-not-started">
                        <div className="fl-column">
                            <div><button className={`submit-btn start-stream-btn${props._draftMode ? ' button-inactive' : ''}`} onClick={props._draftMode ? null : props._startStream}>Record Analytics<br />and Start Bots</button></div>
                        </div>
                    </div>
                }
                {props._streamStarted &&
                    <>
                        <div className="fl-row">
                            <CirclePerc perc={liveTimePerc < 100 ? liveTimePerc : 100}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.clock, IconJsxer.ICON_STYLES.streamControlOptions)}
                            </CirclePerc>
                            <div className="timer-data">
                                <p className="light-text">Stream Active Time</p>
                                <div className="time">
                                    <h4>{`${liveTimeObj.rawHours}h ${liveTimeObj.minutesStr}m ${liveTimeObj.secondsStr}s`}</h4>
                                    <p className="light-text small-text">(Target: {`${targetTimeHms.h}h ${targetTimeHms.m_str}m ${targetTimeHms.s_str}s`})</p>
                                </div>
                            </div>
                        </div>
                        <div className="fl-row grow no-panel-shadow">
                            <HeadedPanel
                                color="an-interactions"
                                headingContent={
                                    <>
                                        <div className="grow"></div>
                                        {IconJsxer.GetIcon(IconJsxer.ICONS.hashtag, IconJsxer.ICON_STYLES.streamStatHeader)}
                                        <div className="grow"></div>
                                    </>
                                }
                                className="small-analytics-stat-panel fl-equal"
                            >
                                <div className="j-center analytics-summary-content">
                                    <h3>{props._analyticsData.interactions?<>{props._analyticsData.interactions.toLocaleString()}</>:<>0</>}</h3>
                                    <p>Interactions</p>
                                </div>
                            </HeadedPanel>

                                <div className="grow"></div>

                            <HeadedPanel
                                color="an-u-users"
                                headingContent={
                                    <>
                                        <div className="grow"></div>
                                        {IconJsxer.GetIcon(IconJsxer.ICONS.multiUsers, IconJsxer.ICON_STYLES.streamStatHeader)}
                                        <div className="grow"></div>
                                    </>
                                }
                                className="small-analytics-stat-panel fl-equal"
                            >
                                <div className="j-center analytics-summary-content">
                                    
                                    <h3>{props._analyticsData.userList?<>{props._analyticsData.userList.toLocaleString()}</>:<>0</>}</h3>
                                    <p>Unique Users</p>
                                </div>
                            </HeadedPanel>

                            <div className="grow"></div>

                            <HeadedPanel
                                color="an-brand-engagement"
                                headingContent={
                                    <>
                                        <div className="grow"></div>
                                        {IconJsxer.GetIcon(IconJsxer.ICONS.bullhorn, IconJsxer.ICON_STYLES.streamStatHeader)}
                                        <div className="grow"></div>
                                    </>
                                }
                                className="small-analytics-stat-panel fl-equal"
                            >
                                <div className="j-center analytics-summary-content">
                                    <h3>{CalculateEngagementTime(props._analyticsData)}</h3>
                                    <p>Feature Engagement</p>
                                </div>
                            </HeadedPanel>
                        </div>
                    </>
                }

                <div></div>

<div className="fl-column">
                            <div className="fl-row">
                                <h5 className="label">OBS Extension Link:</h5>
                                <div className="primary-color">
                                    <a href={props._streamLink} target='_blank'>Open Campaign Studio Extension {IconJsxer.GetIcon(IconJsxer.ICONS.link, IconJsxer.ICON_STYLES.standard)}</a>
                                </div>
                                <div className="primary-color button-active" onClick={copyStreamLinkToClipboard}>
                                    {IconJsxer.GetIcon(IconJsxer.ICONS.copy, IconJsxer.ICON_STYLES.standard)}
                                </div>
                                <h5 className="label">Preview Mode Link:</h5>
                                <div className="primary-color">
                                    <a href={props._streamLink + '?preview'} target='_blank'>Open Campaign Studio Extension Preview</a>
                                </div>
                            </div>
                            <div className="fl-row light-text">Give this url to your streamer so they can open it in OBS or open in a browser when using Test Mode.</div>
                        </div>

                <div className="fl-row">
                    <div className="grow">
                        <h4 className={`${props._overlayState === 'configoverlay' ? '' : 'light-text'}`}>Configuration Overlay</h4>
                    </div>
                    <div>
                        <Toggle id="config_overlay" currentState={props._overlayState === 'configoverlay'} toggleFunc={(e, id) => props._overlayState === 'configoverlay' ? props._setOverlayState('idle') : props._setOverlayState('configoverlay')} />
                    </div>
                </div>

                <div></div>

                <div className="fl-row">
                    <div className="grow">
                        <h4 className={`${props._adBug ? '' : 'light-text'}`}>Ad Bug</h4>
                    </div>
                    <div>
                        <Toggle id="ad_bug" currentState={props._adBug} toggleFunc={(e, id) => props._setAdBug(!props._adBug)} />
                    </div>
                </div>
                {/* props._adBug && */
                    <>
                        <div>Position</div>
                        <div className="fl-row radio-group">
                            {props._adBugPosOptions.map(
                                (val, i) => {
                                    const id = val.replace('-', '_');
                                    const valSplit = val.split('-');
                                    const label = val.replace('-', ' ');
                                    return (
                                        <div className="fl-column fl-equal" key={val}>
                                            <div className={`ad-bug-position-holder abpos-${props._adBugPos === val ? '' : 'un'}selected${props._adBugPos === val ? '' : ' button-active'}`} onClick={(e) => props._setAdBugPos(val)}>
                                                <div className="bg"></div>
                                                <div className={`pos ${valSplit[0]} ${valSplit[1]}`}></div>
                                            </div>
                                            <div className="fl-row">
                                                <input type="radio" name={id} id={id} value={val} checked={props._adBugPos === val ? true : false} onChange={(e) => props._setAdBugPos(val)} />
                                                <label htmlFor={id} className={props._adBugPos === val ? 'checked' : ''}>{label}</label>
                                            </div>
                                        </div>
                                    )
                                }
                            )}
                        </div>
                    </>
                }
            </div>
            <div className="column">

                

                <div className="underlined-panel-heading">
                    <h4>Stream Transcript</h4>
                    <div className="grow"></div>
                </div>
                <div className="tr-scroll-area">
                    {(!props._analyticsData.transcript || props._analyticsData.transcript.length === 0) &&
                        <div>{props._draftMode ? 'Analytics not available for draft campaigns' : 'No Activity Yet Reported'}</div>
                    }
                    {props._analyticsData.transcript && props._analyticsData.transcript.map(
                    (val, i) => {
                        return (
                            <StreamTranscriptItem
                                key = {'transcript'+i}
                                itemData={{
                                    type:val.transcriptType,
                                    startTime: val.startTime,
                                    endTime: val.endTime,
                                    shortDescription:val.shortDescription,
                                    longDescription:val.longDescription
                                }}
                                _showTooltip={props._showTooltip}
                                _hideTooltip={props._hideTooltip}
                            />
                        )
                    }
                    )}
                </div>

                {props._chatConnected && props._featureReady &&
                    <LeftColorBlockHeadedPanel 
                        style={'warning'}
                        headingContent={
                            <div className="fl-row grow">
                                <h4>Encountering Problems?</h4>
                                <div className="grow"></div>
                                <div className="shrink">
                                    <button className="standard-button force-stop-btn" onClick={props._forceStop}>FORCE STOP!</button>
                                </div>
                            </div>
                        }
                    >
                        <div className="fl-column">
                            <div>If you are encountering problems during your stream you can force stop the extension and silently reload it by clicking the <span className="force-stop-text">FORCE STOP</span> button above.</div>
                        </div>
                    </LeftColorBlockHeadedPanel>
                }
                {(!props._chatConnected || !props._featureReady) &&
                    <LeftColorBlockHeadedPanel 
                        style={'warning_orange'}
                        headingContent={
                            <div className="fl-row grow">
                                <h4>You are not ready to stream yet!</h4>
                                <div className="grow"></div>
                                <div className="shrink">
                                    <button className="standard-button tight" onClick={props._activateChat}>Connect to Channels</button>
                                </div>
                            </div>
                        }
                    >
                        <div className="fl-column">
                            <div>To enable all features, you need to make sure you open the OBS extenstion page and click the Connect to Channels button.</div>
                            <div className="fl-row">
                                <h5 className="label">OBS Extension Link:</h5>
                                <div className="primary-color">
                                    <a href={props._streamLink} target='_blank'>Open Campaign Studio Extension {IconJsxer.GetIcon(IconJsxer.ICONS.link, IconJsxer.ICON_STYLES.standard)}</a>
                                </div>
                                <div className="primary-color button-active" onClick={copyStreamLinkToClipboard}>
                                    {IconJsxer.GetIcon(IconJsxer.ICONS.copy, IconJsxer.ICON_STYLES.standard)}
                                </div>
                                <h5 className="label">Preview Mode Link:</h5>
                                <div className="primary-color">
                                    <a href={props._streamLink + '?preview'} target='_blank'>Open Campaign Studio Extension Preview</a>
                                </div>
                            </div>
                        </div>
                    </LeftColorBlockHeadedPanel>
                }

            </div>
        </div>
    )
}

export default TabStreamManager;
