import React, { useState, useEffect } from "react";
import validator from "validator";

import { toast } from "react-toastify";
import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../contexts/AuthContext";

import { registerRoute } from "../../api/routes";
import FormField from "../../components/forms/FormField/FormField";
import "./Register.scss";

import CheckBox from "../../components/forms/CheckBox/CheckBox";
import ClosePanelBtn from "../../components/ClosePanelBtn/ClosePanelBtn";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import { NOT_STRONG_PASSWORD_ERROR, STRONG_PASSWORD_SETTINGS } from "../../helpers/AuthFunctions";

const Register = (props) => {
    console.log("props.email = ",props.email);
    const [email, setEmail] = useState((props.email) ? props.email : "");
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [privacyCookiesChecked, setPrivacyCookiesChecked] = useState(false);

    const [usernameError, setUsernameError] = useState("");
    const [nameError, setNameError] = useState("");
    const [emailError, setemailError] = useState("");
    const [passwordError, setpasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [privacyCookiesError, setPrivacyCookiesError] = useState("");

    const [formValid, setFormValid] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    // const [authContext, setAuthContext] = useContext(AuthContext);

    const navigate = useNavigate();

    const handleValidation = (event) => {
        let formIsValid = true;

        /*if (!validator.isAlphanumeric(username) || username.length < 3 || username.length > 40) {
            formIsValid = false;
            setUsernameError("Username Not Valid, letters and numbers only, must be at least 3 character and no more than 40");
        } else {
            setUsernameError("");
        }*/

        // Allow spaces in display name
        /*if (!validator.isAlphanumeric(name, undefined, { ignore: ' ' }) || name.length < 2 || name.length > 60) {
            formIsValid = false;
            setNameError("Display Name Not Valid, must be at least 2 character and no more than 60");
        } else {
            setNameError("");
        }*/

        if (!validator.isEmail(email)) {
            formIsValid = false;
            setemailError("Email Not Valid");
        } else {
            setemailError("");
        }

        if (
            !validator.isStrongPassword(password, STRONG_PASSWORD_SETTINGS)
        ) {
            formIsValid = false;
            setpasswordError(
                NOT_STRONG_PASSWORD_ERROR
            );
        } else {
            setpasswordError("");
        }

        if (confirmPassword !== password) {
            formIsValid = false;
            setConfirmPasswordError(
                "Password and Confirm Password must match"
            );
        } else {
            setConfirmPasswordError("");
        }

        if (!privacyCookiesChecked) {
            formIsValid = false;
            setPrivacyCookiesError("Please tick the box above");
        } else {
            setPrivacyCookiesError("");
        }

        setFormValid(formIsValid);
        return formIsValid;
    };

    const registerFormSubmitHandler = (e) => {
        e.preventDefault();
        const formValid = handleValidation();

        if (formValid) {
            setIsSubmitting(true);

            axios
                .post(registerRoute, {
                    username:'',
                    name: 'TWITCH_LOCAL',
                    email: email,
                    password: password,
                })
                .then(function (response) {

                    if(response.code != 500)
                    {
                        setIsSubmitting(false);
                        /* const data = response.data
                        setAuthContext((oldValues) => {
                          return { ...oldValues, token:data.token}
                        }) */
    
                        toast.success("Registration Successful!");
                        navigate("/login");
                    }
                    else{
                        toast.error("Error - You need to have an invitation to sign up!");
              
                    }

                
                })
                .catch(function (error) {
                    setIsSubmitting(false);
                    toast.error('Error - ' + error.response.data.message);
                    // console.log(error.response)
                });
        }
    };

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="form-holder">
                <form id="registerform" className="form">
                    <h2>Create account</h2>
                 
                    <FormField
                        type="email"
                        id="email"
                        placeholder="Enter Email"
                        setFunc={setEmail}
                        label="Email"
                        value={email}
                        error={emailError}
                    />
                    <FormField
                        type="password"
                        id="password"
                        placeholder="Enter Password"
                        setFunc={setPassword}
                        label="Password"
                        error={passwordError}
                    />
                    <FormField
                        type="password"
                        id="confirmPassword"
                        placeholder="Confirm Password"
                        setFunc={setConfirmPassword}
                        label="Confirm Password"
                        error={confirmPasswordError}
                    />
                    <CheckBox
                        id="privacy-cookies-check"
                        label="By ticking this box I agree to the Privacy Policy and the use of Cookies"
                        setFunc={setPrivacyCookiesChecked}
                        error={privacyCookiesError}
                        initialState={false}
                    />
                    <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : registerFormSubmitHandler}>
                        {isSubmitting ? "Submitting" : "Submit"}
                    </button>

                    <div className="footer">
                        <p>By creating an account you agree to the Terms of Use</p>
                    </div>
                </form>
            </div>
        </PopUpPanel>
    )
}

export default Register;
