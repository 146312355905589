import React, { useContext, useState, useEffect } from "react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/AuthContext";
import { ProfilePicURL } from "../../helpers/ProfilePicURL";

import "./Settings.scss";
import { changeBotStatusRoute, changePasswordRoute } from "../../api/routes";
import FormField from "../../components/forms/FormField/FormField";
import TabbedPanel from "../../components/TabbedPanel/TabbedPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenSquare, faCopy } from "@fortawesome/fontawesome-free-solid";
import EditProfileImage from "./EditProfileImage";
import EditDisplayName from "./EditDisplayName";
import { getAcTypeDisplayName } from "../../helpers/AccountTypes";
import ProfilePic from "../../components/ProfilePic/ProfilePic";
import { IconJsxer } from "../../helpers/IconHelper";
import { assets } from "../../assets/assets";

import validator from "validator";
import { NOT_STRONG_PASSWORD_ERROR, STRONG_PASSWORD_SETTINGS } from "../../helpers/AuthFunctions";
import { getLocaleData, getShortDateString, getShortDateTimeString } from "../../helpers/Dates";
import { useNavigate, } from "react-router-dom";
import {getUserCampaigns } from "../../api/routes";
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";
import CampaignSummaryPanel from "../../components/Campaigns/CampaignSummaryPanel";
import { orderCampaignsAccordingToStatus } from "../../helpers/CampaignsHelper";

const Settings = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
    const [oldPasswordError, setOldPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const [authContext, setAuthContext] = useContext(AuthContext);

        const [showChangeProfileImageForm, setShowChangeProfileImageForm] = useState(false);
        const [showChangeDisplayForm, setShowChangeDisplayForm] = useState(false);

    const changePassword = (e) => {
        e.preventDefault();
        if(oldPassword !== '' && newPassword === newPasswordConfirm && newPassword !== '' && validator.isStrongPassword(newPassword, STRONG_PASSWORD_SETTINGS))
        {
            setPasswordError('');
            setConfirmPasswordError('');
            setIsSubmitting(true);
            axios
            .post(changePasswordRoute, {oldPassword:oldPassword, newPassword:newPassword, withCredentials:true})
            .then(function (response) {
                setIsSubmitting(false);
                toast.success("Password Changed Successfully!");
                document.getElementById("changepasswordform").reset();
            })
            .catch(function (error) {
                setIsSubmitting(false);
                toast.error('Your current password is incorrect!');
            });
        }
        else
        {
            if (oldPassword === '') {
                setOldPasswordError('Please enter your current password.');
            } else {
                setOldPasswordError('');
            }
            if (newPassword === '') {
                setPasswordError('Your new password can not be empty.');
            } else
            if (!validator.isStrongPassword(newPassword, STRONG_PASSWORD_SETTINGS)) {
                setPasswordError(NOT_STRONG_PASSWORD_ERROR);
            } else {
                setPasswordError('');
            }
            if (newPassword !== newPasswordConfirm) {
                console.log('PASSWORDS DONT MATCH');
                // toast.error('Your new passwords do not match!');
                setConfirmPasswordError('Your new passwords do not match.');
            } else {
                setConfirmPasswordError('');
            }
        }
      };

      const setTabs = () => {
        if(authContext.userData.isTwitchLogin)
        {
            return [{label: 'Overview'}, {label: 'My Campaigns'}]
        }
        else
        {
            return [{label: 'Overview'}, {label: 'My Campaigns'},  {label: 'Password'}]
        }
      }
      const toggleChangeProfileImage = (e) => {
        setShowChangeProfileImageForm(!showChangeProfileImageForm);
      }
      const toggleChangeDisplayName = () => {
        setShowChangeDisplayForm(!showChangeDisplayForm);
      }
      const copyAccountIDToClipboard = () =>{
        navigator.clipboard.writeText(authContext.userData._id);
        toast.success("Account ID copied to clipboard!");
      }

      const [campaignsData, setCampaignsData] =  useState([]);
      
      const [activeCampaigns, setActiveCampaigns] =  useState([]);
      const [activeCampaignsCollapsed, setActiveCampaignsCollapsed] =  useState(false);
      const [archivedCampaigns, setArchivedCampaigns] =  useState([]);
      const [archivedCampaignsCollapsed, setArchivedCampaignsCollapsed] =  useState(true);
      const [draftCampaigns, setDraftCampaigns] =  useState([]);
      const [draftCampaignsCollapsed, setDraftCampaignsCollapsed] =  useState(true);
      
      const [showAllCampaigns, setShowAllCampaigns] =  useState(true);
      const navigate = useNavigate();
      const fetchCampaignList = () => {
          axios
          .get(getUserCampaigns, {params:{campaignStatusFilter: {id: 'all'}}, withCredentials: true})
          .then((response) => {
              console.log(response.data.campaigns)
              //campaigns = response.data.campaigns;
              orderCampaignsAccordingToStatus(response.data.campaigns);
              setCampaignsData( response.data.campaigns);

              // now build a list of archived, active and draft campaigns
                let active = [];
                let archived = [];
                let draft = [];
                response.data.campaigns.forEach((val, i) => {
                    if(val.archived)
                    {
                        archived.push(val);
                    }
                    else if(val.draft)
                    {
                        draft.push(val);
                    }
                    else
                    {
                        active.push(val);
                    }
                });
                setActiveCampaigns(active);
                setArchivedCampaigns(archived);
                setDraftCampaigns(draft);
          })
          .catch((error) => {
            //
            //toast.error(error.response.data.msg);
          });
      }
      const openCampaignOverview = (e, campaignID) => {
          navigate(TOP_LEVEL_NAV_ROUTES.CAMPAIGN_OVERVIEW.replace(':id', campaignID));
      }

      useEffect(() => {
        fetchCampaignList();   
      }, [])

      const [botsButtonActive, setBotsButtonActive] = useState(true);
      const toggleBotAuthorisation = (e) => {
        e.preventDefault();
        setBotsButtonActive(false);
        axios
        .post(changeBotStatusRoute, {withCredentials: true, userID: authContext.userData._id})
        .then((response) => {
            setBotsButtonActive(true);
            toast.success('Bot authorisation changed');
            setAuthContext(
                (oldVals) => {
                    const oldBotVal = oldVals.userData.isBotAuthorised;
                    const userData = {...oldVals.userData};
                    userData.isBotAuthorised = !oldBotVal;
                    const updatedContextData = {...oldVals};
                    updatedContextData.userData = userData;
                    return updatedContextData;
                }
            );
        })
        .catch((error) => {
            setBotsButtonActive(true);
            toast.error(error.response.data.msg);
        });
    }

    return (
        <div className="content-page-container">
            <div className="content-page-top">
                <h1>Account Settings</h1>
            </div>
            <div className="content-page-content-container">
                <div className="content-page-main-content">
                    <TabbedPanel tabs={setTabs()} switchTabCallback={(tabId, tabData) => setCurrentTab(tabId)} extraPanelClass="login-panel-min-height" tabWidth="25%">
                    <div className="settings-container no-panel-shadow">
                        {currentTab === 1 &&
                        <>
                            {activeCampaigns.length === 0 && archivedCampaigns.length === 0 && draftCampaigns.length === 0 &&
                                <h4>
                                    No campaigns found.
                                </h4>
                            }
                            {(activeCampaigns.length !== 0 || archivedCampaigns.length !== 0 || draftCampaigns.length !== 0) &&
                                <div className="scroll-area small-headed-panels">
                                    <div className="fl-column">
                                    {activeCampaigns.length > 0 &&
                                    <>
                                        <div className="underlined-panel-heading" onClick={() => setActiveCampaignsCollapsed(!activeCampaignsCollapsed)}>
                                            <h5 className={activeCampaignsCollapsed ? 'light-text' : ''}>Active Campaigns ({activeCampaigns.length})</h5>
                                            <div className="grow"></div>
                                            {activeCampaignsCollapsed && <div className="small-text light-text">(Click to expand)</div>}
                                            <div>
                                                {IconJsxer.GetIcon(activeCampaignsCollapsed ? IconJsxer.ICONS.chevronDown : IconJsxer.ICONS.chevronUp, IconJsxer.ICON_STYLES.settingsEdit, 'light-text')}
                                            </div>
                                        </div>
                                        <div className={`campaign-list-holder${activeCampaignsCollapsed ? ' collapsed' : ''}`} >
                                            {activeCampaigns.map((val, i) => {
                                                return (
                                                    <CampaignSummaryPanel campaign={val} key={'c_' + i} listView={true} />
                                                );
                                            })}
                                        </div>
                                        <div></div>
                                    </>
                                    }
                                    {activeCampaigns.length === 0 &&
                                        <>
                                            <div className="underlined-panel-heading inactive">
                                                <h5 className='light-text'>No Active Campaigns</h5>
                                            </div>
                                            <div></div>
                                        </>
                                    }
                                    {draftCampaigns.length > 0 &&
                                    <>
                                        <div className="underlined-panel-heading" onClick={() => setDraftCampaignsCollapsed(!draftCampaignsCollapsed)}>
                                            <h5 className={draftCampaignsCollapsed ? 'light-text' : ''}>Draft Campaigns ({draftCampaigns.length})</h5>
                                            <div className="grow"></div>
                                            {draftCampaignsCollapsed && <div className="small-text light-text">(Click to expand)</div>}
                                            <div>
                                                {IconJsxer.GetIcon(draftCampaignsCollapsed ? IconJsxer.ICONS.chevronDown : IconJsxer.ICONS.chevronUp, IconJsxer.ICON_STYLES.settingsEdit, 'light-text')}
                                            </div>
                                        </div>
                                        <div className={`campaign-list-holder${draftCampaignsCollapsed ? ' collapsed' : ''}`} >
                                            {draftCampaigns.map((val, i) => {
                                                return (
                                                    <CampaignSummaryPanel campaign={val} key={'c_' + i} listView={true} />
                                                );
                                            })}
                                        </div>
                                        <div></div>
                                    </>
                                    }
                                    {draftCampaigns.length === 0 &&
                                        <>
                                            <div className="underlined-panel-heading inactive">
                                                <h5 className='light-text'>No Draft Campaigns</h5>
                                            </div>
                                            <div></div>
                                        </>
                                    }
                                    {archivedCampaigns.length > 0 &&
                                    <>
                                        <div className="underlined-panel-heading" onClick={() => setArchivedCampaignsCollapsed(!archivedCampaignsCollapsed)}>
                                            <h5 className={archivedCampaignsCollapsed ? 'light-text' : ''}>Archived Campaigns ({archivedCampaigns.length})</h5>
                                            <div className="grow"></div>
                                            {archivedCampaignsCollapsed && <div className="small-text light-text">(Click to expand)</div>}
                                            <div>
                                                {IconJsxer.GetIcon(archivedCampaignsCollapsed ? IconJsxer.ICONS.chevronDown : IconJsxer.ICONS.chevronUp, IconJsxer.ICON_STYLES.settingsEdit, 'light-text')}
                                            </div>
                                        </div>
                                        <div className={`campaign-list-holder${archivedCampaignsCollapsed ? ' collapsed' : ''}`} >
                                            {archivedCampaigns.map((val, i) => {
                                                return (
                                                    <CampaignSummaryPanel campaign={val} key={'c_' + i} listView={true} />
                                                );
                                            })}
                                        </div>
                                        <div></div>
                                    </>
                                    }
                                    {archivedCampaigns.length === 0 &&
                                    <>
                                        <div className="underlined-panel-heading inactive">
                                            <h5 className='light-text'>No Archived Campaigns</h5>
                                        </div>
                                        <div></div>
                                    </>
                                    }
                                    </div>
                                </div>
                            }
                        </>
                        }
                        {currentTab === 2 &&
                        <>
                            <h2>Change Password</h2>
                            <form className="form-holder change-password-form">
                                <FormField
                                    type="password"
                                    id="oldPassword"
                                    placeholder="Current Password"
                                    setFunc={setOldPassword}
                                    label="Current Password"
                                    error={oldPasswordError}
                                />
                                <FormField
                                    type="password"
                                    id="newPassword"
                                    placeholder="New Password"
                                    setFunc={setNewPassword}
                                    label="New Password"
                                    error={passwordError}
                                />
                                 <FormField
                                    type="password"
                                    id="newPassword"
                                    placeholder="Confirm New Password"
                                    setFunc={setNewPasswordConfirm}
                                    label="Confirm New Password"
                                    error={confirmPasswordError}
                                />
                                <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : changePassword}>
                                    {isSubmitting ? "Submitting" : "Submit"}
                                </button>
                            </form>
                        </>
                        }
                        {currentTab === 0 &&
                        <>
                            <div className="s-row">
                                <div className="s-profile-pic">
                                    <ProfilePic size="extralarge" className="pp-style" />
                                    {!authContext.userData.isTwitchLogin && 
                                        <div className="icon-overlay s-edit-icon pp-edit" onClick={toggleChangeProfileImage}>{IconJsxer.GetIcon(IconJsxer.ICONS.edit, IconJsxer.ICON_STYLES.settingsEdit)}</div>
                                    }
                                    {authContext.userData.isTwitchLogin && 
                                        <div className="icon-overlay"><img src={assets.TwitchPPicIcon} alt="" className="tw-ppic-icon" /></div>
                                    }
                                </div>
                                <div className="column-cell">
                                    <div className="s-row small-gap">
                                        <div className="column-cell">
                                        <h2>{authContext.userData.firstName} {authContext.userData.lastName}</h2>
                                            <h4>{authContext.userData.name}</h4>
                                        </div>
                                        <div className="column-cell">
                                            {!authContext.userData.isTwitchLogin && (<span className="button-active s-edit-icon" onClick={toggleChangeDisplayName}>{IconJsxer.GetIcon(IconJsxer.ICONS.edit, IconJsxer.ICON_STYLES.settingsEdit)}</span>) }
                                        </div>
                                    </div>
                                    <div className="s-row">
                                        <p className="small-text light-text">Member since {getShortDateString(authContext.userData.dateCreated)}</p>
                                    </div>
                                    <div className="s-row">
                                        <p className="small-text light-text">Last Active {getShortDateTimeString(authContext.userData.lastActive)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="s-column no-gap">
                                <div className="label">Email</div>
                                <p className="indented tight">{authContext.userData.email}</p>
                            </div>
                            {authContext.userData.isTwitchLogin && 
                                <div className="s-column no-gap">
                                    <div className="label">Twitch Username</div>
                                    <p className="indented tight">{authContext.userData.name}</p>
                                </div>
                            }
                            <div className="s-column no-gap">
                                <div className="label">Account ID</div>
                                <div className="s-row small-gap">
                                    <p className="indented tight">{authContext.userData._id}</p>
                                    <span className="button-active s-edit-icon" onClick={copyAccountIDToClipboard}>{IconJsxer.GetIcon(IconJsxer.ICONS.copy, IconJsxer.ICON_STYLES.settingsEdit)}</span>
                                </div>
                            </div>
                            <div className="s-column no-gap">
                                <div className="label">Account Type</div>
                                <p className="indented tight">{getAcTypeDisplayName(authContext.userData.accountType)}</p>
                            </div>

                            {authContext.userData.isTwitchLogin && 
                                <div className="s-column no-gap">
                                    <div className="label">Bot Posting</div>
                                    <div className="s-row small-gap">
                                        <p className="indented tight">{`${!authContext.userData.isBotAuthorised ? 'Not ' : ''}`}Authorised</p>
                                        <span className={authContext.userData.isBotAuthorised ? 'success-color' : 'error-color'}>
                                            {IconJsxer.GetIcon(IconJsxer.ICONS.bot, IconJsxer.ICON_STYLES.settingsEdit)}
                                        </span>
                                    </div>
                                    <div className="fl-row">
                                        <div className="shrink">
                                            <button className={`standard-button${botsButtonActive ? '' : ' button-inactive'}`} onClick={botsButtonActive ? toggleBotAuthorisation : null}>
                                                {authContext.userData.isBotAuthorised ? 'De-authorise' : 'Authorise'}
                                            </button>
                                        </div>
                                        <div className="grow"></div>
                                    </div>
                                </div>
                            }

                            {/* How to map an object like an array!
                            
                            Object.entries(authContext.userData).map(
                                ([k, v], i) => <p>{k + ': ' + v}</p>
                            )
                            
                            */}
                        </>
                        }
                    </div>
                    </TabbedPanel>
                    {showChangeProfileImageForm && <EditProfileImage closePanelFunc={toggleChangeProfileImage} />}
                    {showChangeDisplayForm && <EditDisplayName closePanelFunc={toggleChangeDisplayName} />}
                </div>
            </div>
        </div>
    );
}

export default Settings;
