import { getFeatureAssetBaseUrl } from "./FeatureAssets";

export const getCustomFontUrl = (fontFile, campaignId) => {
    const fontBaseUrl = getFeatureAssetBaseUrl() + `campaigns/${campaignId ?? 'no-campaign'}/fonts/`;
    const fontUrl = fontBaseUrl + fontFile;
    return fontUrl;
}

export const applyFontPreview = (fontFamily, fontUrl, elementClass = '.apply-custom-font') => {
    // Create a font face style in the header if it doesn't already exist
    const styleId = 'custom-font-' + fontFamily.split(' ').join('-') + '-style';
    const existingStyle = document.getElementById(styleId);
    if (!existingStyle) {
        const customFontStyle = document.createElement('style');
        customFontStyle.id = styleId;
        customFontStyle.innerHTML = `
            @font-face {
                font-family: '${fontFamily}';
                src: url('${fontUrl}') format('truetype');
            }
        `;
        document.head.appendChild(customFontStyle);
    }

    // Create a style in the header for the custom font preview element, if it exists apply the updated font family, otherwise create it
    const elementStyleId = 'custom-font-preview-' + elementClass.substring(1) + '-style';
    const existingElementStyle = document.getElementById(elementStyleId);
    if (!existingElementStyle) {
        const customFontPreviewElementStyle = document.createElement('style');
        customFontPreviewElementStyle.id = elementStyleId;
        customFontPreviewElementStyle.innerHTML = `
            ${elementClass} {
                font-family: '${fontFamily}';
            }
        `;
        document.head.appendChild(customFontPreviewElementStyle);
    } else {
        existingElementStyle.innerHTML = `
            ${elementClass} {
                font-family: '${fontFamily}';
            }
        `;
    }
}

export const FontPreviewBlock = (props) => {
    return (
        <div className={`fl-column ${props.className ?? 'apply-font'}`} style={{ maxHeight: (props.maxHeight ?? '28rem'), overflowY: 'auto' }}>
            <h1>The quick brown fox jumps over the lazy dog.</h1>
            <h2>The quick brown fox jumps over the lazy dog.</h2>
            <h3>The quick brown fox jumps over the lazy dog.</h3>
            <h4>The quick brown fox jumps over the lazy dog.</h4>
            <h5>The quick brown fox jumps over the lazy dog.</h5>
            <p>The quick brown fox jumps over the lazy dog.</p>
        </div>
    )
}
