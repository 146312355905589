import React, { useEffect, useState } from "react";

import PopUpPanel from "../../PopUpPanel/PopUpPanel";
import FontPicker from "font-picker-react";

import "./FontSelector.scss";
import TabbedPanel from "../../TabbedPanel/TabbedPanel";
import FormDropDown from "../DropDownList/FormDropDown";
import { getFeatureAssetBaseUrl } from "../../../helpers/FeatureAssets";
import { applyFontPreview, FontPreviewBlock, getCustomFontUrl } from "../../../helpers/CustomFonts";

const FontSelectorPopup = (props) => {
    const customFonts = props.customFonts;
    const isCustomFont = (fontFamily) => {
        // console.log('isCustomFont', fontFamily, customFonts);
        if (customFonts) {
            const font = customFonts.find(f => f.fontFamily === fontFamily);
            return font ? true : false;
        }
        return false;
    }

    const [activeFontFamily, setActiveFontFamily] = useState(props.font || 'Roboto');
    const [activeGoogleFont, setActiveGoogleFont] = useState(isCustomFont(props.font) ? 'Roboto' : props.font);
    const [activeCustomFont, setActiveCustomFont] = useState(isCustomFont(props.font) ? props.font : (customFonts?.[0]?.fontFamily ?? ''));
    

    const initialTab = isCustomFont(props.font) ? 'custom' : 'google';
    const [currentTab, setCurrentTab] = useState(initialTab);

    const tabData = [
        { label: 'Google Fonts', id: 'google' },
        { label: 'Custom Fonts', id: 'custom', hidden: (!customFonts || customFonts.length === 0) }
    ];

    const customFontsDropdown = customFonts.map ? customFonts.map(f => ({ label: f.fontFamily, value: f.fontFamily, fontFile: f.fontFilename })) : [];
    const [selectedCustomFontIndex, setSelectedCustomFontIndex] = useState(customFontsDropdown.findIndex(f => f.value === props.font) >= 0 ? customFontsDropdown.findIndex(f => f.value === props.font) : 0);

    const applyCustomFontToPreview = (fontData = null) => {
        if (!customFonts || customFonts.length === 0) {
            return;
        }

        // console.log('applyCustomFontToPreview', fontData, customFontsDropdown, selectedCustomFontIndex);

        if (fontData === null) {
            fontData = customFontsDropdown[selectedCustomFontIndex];
        }
        applyFontPreview(fontData.value, getCustomFontUrl(fontData.fontFile, props.campaignId));
    }

    useEffect(() => {
        applyCustomFontToPreview();
    }, []);

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc} className="font-popup">
            <div className="form-holder co-popup-content">

                <h2>Select Font</h2>

                <TabbedPanel
                    tabs={tabData}
                    switchTabCallback={
                        (tabId, tabData) => {
                            setCurrentTab(tabData.id);
                            if (tabData.id === 'custom') {
                                setTimeout(() => {
                                    applyCustomFontToPreview(customFontsDropdown[selectedCustomFontIndex]);
                                }, 10);
                                setActiveFontFamily(activeCustomFont);
                            } else {
                                setActiveFontFamily(activeGoogleFont);
                            }
                        }
                    }
                    initialTab={tabData.findIndex(t => t.id === initialTab)}
                    tabWidth="33%"
                >
                    <>
                        {currentTab === 'google' &&
                            <div className="fl-column font-selector-content">
                                <FontPicker
                                    apiKey="AIzaSyCK-FT-TdFUvWTWBQ_hxsoU_HS6GHeytWg"
                                    activeFontFamily={isCustomFont(activeFontFamily) ? 'Roboto' : activeFontFamily}
                                    limit={250}
                                    onChange={(nextFont) =>
                                        setActiveFontFamily(nextFont.family)
                                    }
                                />
                                <FontPreviewBlock className="apply-font" />
                            </div>
                        }
                        {currentTab === 'custom' &&
                            <div className="fl-column font-selector-content">
                                <FormDropDown
                                    id="customFonts"
                                    label="Custom Fonts"
                                    showLabel={false}
                                    showErrors={false}
                                    currentIndex={selectedCustomFontIndex}
                                    selectFunc={
                                        (e, id, item) => {
                                            setActiveFontFamily(item.value); 
                                            setSelectedCustomFontIndex(item.index);
                                            applyCustomFontToPreview(item);
                                        }
                                    }
                                    items={customFontsDropdown}
                                />
                                <FontPreviewBlock className="apply-custom-font" />
                            </div>
                        }
                    </>
                </TabbedPanel>
                <button className="standard-button" onClick={props.selectFunc ? () => {props.selectFunc(activeFontFamily); props.closePanelFunc()} : null}>Confirm Selection</button>
            </div>
        </PopUpPanel>
    )
}

export default FontSelectorPopup;
